<template>
  <div>
    <div class="d-flex align-items-center">
      <slot name="parent" />
      <Button variant="text" size="small" class="p-0" :id="computedTarget">
        <div class="d-flex align-items-center">
          <CircledQuestionMarkSVG class="icon ml-2" />
        </div>
      </Button>
    </div>

    <b-tooltip
      :variant="variant"
      :placement="placement"
      :fallback-placement="fallbackPlacement"
      :target="computedTarget"
      triggers="hover focus"
      :container="computedContainer"
      :boundary-padding="boundaryPadding"
    >
      <template #default>
        <slot>
          <span class="text">{{ tooltipText }} </span>
        </slot>
      </template>
    </b-tooltip>
  </div>
</template>

<script>
import CircledQuestionMarkSVG from '@/assets/images/circle-question-regular.svg';
import { Button } from '@/components';

export default {
  name: 'Tooltip',
  components: {
    CircledQuestionMarkSVG,
    Button,
  },
  props: {
    // tooltip props from https://getbootstrap.com/docs/4.0/components/tooltips/
    tooltipText: {
      type: String,
      required: false,
    },
    target: {
      type: String,
      default: '',
      required: true,
    },
    hasLabel: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      validator: function (value) {
        return [
          'top',
          'topleft',
          'topright',
          'right',
          'righttop',
          'rightbottom',
          'bottom',
          'bottomleft',
          'bottomright',
          'left',
          'lefttop',
          'leftbottom',
        ].includes(value);
      },
      default: 'top',
    },
    //Auto-flip placement behaviour of the tooltip, relative to the trigger element
    //flip, clockwise, counterclockwise, or an array of valid placements evaluated from left to right
    fallbackPlacement: {
      type: [String, Array],
      default: 'flip',
    },
    container: {
      type: String,
      validator: function (value) {
        return ['target', 'body'].includes(value);
      },
      default: 'target',
    },
    //default bootstrap theming https://bootstrap-vue.org/docs/reference/theming#default-theme-colors
    variant: {
      validator: function (value) {
        return [
          'primary',
          'secondary',
          'success',
          'danger',
          'warning',
          'info',
          'light',
          'dark',
        ].includes(value);
      },
      default: 'light',
    },
    boundaryPadding: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    computedContainer() {
      return this.container === 'target' ? this.target : this.container;
    },
    computedTarget() {
      return `${this.target}-button`;
    },
  },
};
</script>

<style lang="scss">
.tooltip .tooltip-inner {
  max-width: 100%;
}
.tooltip.b-tooltip.b-tooltip-light {
  opacity: 1;
}
</style>
