import Vue from 'vue';
import Router from 'vue-router';
import { DECISION_TYPES } from './constants';

Vue.use(Router);

import Home from './views/Home.vue';
import Maintenance from './views/Maintenance.vue';

import store from './store';
import { SET_LANGUAGE, HAS_ERROR, NOTIFICATION } from './types';
import { STEPS, ROUTES } from './constants';
import i18n from './i18n';
import {
  setDocumentTitle,
  setHtmlElementLanguage,
  kebabToSnakeCaseForTranslation,
} from './utils';

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ selector: to.hash, behavior: 'smooth' });
        }, 250);
      });
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0, behavior: 'smooth' });
        }, 250);
      });
    }
  },
  routes: [
    {
      path: ROUTES.MAINTENANCE.path,
      name: ROUTES.MAINTENANCE.name,
      component: Maintenance,
    },
    {
      path: ROUTES.HOME.path,
      name: ROUTES.HOME.name,
      component: Home,
    },
    {
      path: ROUTES.SELECT_BUNDLE.path,
      name: ROUTES.SELECT_BUNDLE.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.SELECT_BUNDLE.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "select-bundle" */ './views/SelectBundle.vue'
        ),
    },
    {
      path: ROUTES.APPLY.path,
      name: ROUTES.APPLY.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.APPLY.name),
      },
      component: () =>
        // route level code-splitting
        // this generates a separate chunk (apply.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        import(/* webpackChunkName: "apply" */ './views/Apply.vue'),
    },
    {
      path: ROUTES.LOGIN_SUCCESS.path,
      name: ROUTES.LOGIN_SUCCESS.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.LOGIN_SUCCESS.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "login-success" */ './views/Login/Success.vue'
        ),
    },
    {
      path: ROUTES.LOGIN_FAIL.path,
      name: ROUTES.LOGIN_FAIL.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.LOGIN_FAIL.name),
      },
      component: () =>
        import(/* webpackChunkName: "login-fail" */ './views/Login/Fail.vue'),
    },
    {
      path: ROUTES.SELECT_STORE.path,
      name: ROUTES.SELECT_STORE.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.SELECT_STORE.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "select-store" */ './views/SelectStore/SelectStore.vue'
        ),
    },
    {
      path: ROUTES.FAQ.path,
      name: ROUTES.FAQ.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.FAQ.name),
      },
      component: () => import(/* webpackChunkName: "faq" */ './views/Faq.vue'),
    },
    {
      path: ROUTES.LOAN_APPLICATION.path,
      name: ROUTES.LOAN_APPLICATION.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.LOAN_APPLICATION.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "loan-application" */ './views/LoanApplication/LoanApplication.vue'
        ),
    },
    {
      path: ROUTES.LOAN_APPLICATION_LOADING.path,
      name: ROUTES.LOAN_APPLICATION_LOADING.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.LOAN_APPLICATION_LOADING.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "loan-application-loading" */ './views/LoanApplication/LoanApplicationLoading.vue'
        ),
    },
    {
      path: ROUTES.LOAN_APPLICATION_FAIL.path,
      name: ROUTES.LOAN_APPLICATION_FAIL.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.LOAN_APPLICATION_FAIL.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "loan-application-fail" */ './views/LoanApplication/Fail.vue'
        ),
    },

    {
      path: ROUTES.DECISION_DENIED.path,
      name: ROUTES.DECISION_DENIED.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.DECISION_DENIED.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "decision-denied" */ './views/Decisions/Denied.vue'
        ),
    },
    {
      path: ROUTES.DECISION_SIGN.path,
      name: ROUTES.DECISION_SIGN.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.DECISION_SIGN.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "decision-sign" */ './views/Decisions/Sign.vue'
        ),
    },
    {
      path: ROUTES.LOAN_APPLICATION_SUCCESS.path,
      name: ROUTES.LOAN_APPLICATION_SUCCESS.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.LOAN_APPLICATION_SUCCESS.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "loan-application-success" */ './views/LoanApplication/Success.vue'
        ),
    },
    {
      path: ROUTES.BANK_ID_FAIL.path,
      name: ROUTES.BANK_ID_FAIL.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.BANK_ID_FAIL.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "bank-id-fail" */ './views/BankId/Fail.vue'
        ),
    },
    {
      path: ROUTES.CREDIT_CARD_PAYMENT.path,
      name: ROUTES.CREDIT_CARD_PAYMENT.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.CREDIT_CARD_PAYMENT.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "credit-card-pay" */ './views/CreditCard/Payment.vue'
        ),
    },
    {
      path: ROUTES.CREDIT_CARD_PAYMENT_FAIL.path,
      name: ROUTES.CREDIT_CARD_PAYMENT_FAIL.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.CREDIT_CARD_PAYMENT_FAIL.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "credit-card-pay-fail" */ './views/CreditCard/PaymentFail.vue'
        ),
    },
    {
      path: '*',
      redirect: { name: ROUTES.HOME.name },
    },
  ],
});

if (window.config.vue.vueAppMaintenance === 'true') {
  router.beforeEach((to, from, next) => {
    if (to.name !== ROUTES.MAINTENANCE.name) {
      router.replace({
        path: ROUTES.MAINTENANCE.path,
        redirect: ROUTES.MAINTENANCE.path,
      });
    }
    next();
  });
} else {
  router.beforeEach(async (to, from, next) => {
    const {
      HOME,
      APPLY,
      LOAN_APPLICATION,
      LOAN_APPLICATION_LOADING,
      LOAN_PAYMENT_RESERVATION_LOADING,
    } = ROUTES;
    const {
      state: {
        currentStep,
        hasError,
        loanApplicationData,
        loanApplicationDecision,
      },
      commit,
    } = store;

    let goto;

    switch (to.name) {
      case APPLY.name:
      case LOAN_APPLICATION.name:
        if (STEPS.apply > currentStep) {
          goto = { name: HOME.name };
        }
        break;
      case LOAN_APPLICATION_LOADING.name:
        if (STEPS.apply > currentStep || !loanApplicationData) {
          goto = { name: HOME.name };
        } else if (from.name == null) {
          goto = { name: LOAN_APPLICATION.name };
        }
        break;
      case LOAN_PAYMENT_RESERVATION_LOADING.name:
        if (
          from.name === ROUTES.DECISION_DENIED.name &&
          loanApplicationDecision === DECISION_TYPES.REJECTED
        ) {
          goto = { name: ROUTES.DECISION_DENIED.name };
        }

        break;
      default:
    }

    if (hasError) {
      commit(HAS_ERROR, false);
    }
    commit(NOTIFICATION, null);

    goto ? next(goto) : next();
  });
}

router.afterEach((to) => {
  // On every route change set new document title
  setDocumentTitle(
    to.meta.titleKey ? i18n.t(`ROUTER.${to.meta.titleKey}`) : null,
    i18n.t('ROUTER.DEFAULT_TITLE'),
  );

  // If url has query param ?lang=, set locale
  const languageFromQuery = to.query.lang;
  if (i18n.availableLocales.includes(languageFromQuery)) {
    i18n.locale = languageFromQuery;
    setHtmlElementLanguage(languageFromQuery);
    setDocumentTitle(
      to.meta.titleKey ? i18n.t(`ROUTER.${to.meta.titleKey}`) : null,
      i18n.t('ROUTER.DEFAULT_TITLE'),
    );
    store.dispatch(SET_LANGUAGE, languageFromQuery);
  }
});
export default router;
