<template>
  <div
    v-if="steps"
    class="d-flex flex-row align-items-center justify-content-between wrapper"
  >
    <div
      v-for="(step, index) in steps"
      :key="`step-${index}`"
      :class="[step === activeStep ? 'dot dot__active' : 'dot']"
    >
      {{ step }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepsCounter',
  components: {},
  props: {
    steps: {
      type: Number,
      default: 0,
    },
    activeStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.wrapper {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    background-color: $color-grey-dark;
    width: 100%;
    height: 0.13rem;
  }
}
.dot {
  width: 2rem;
  height: 2rem;
  text-align: center;
  padding-top: 0.4rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: $color-grey-dark;
  font-weight: $font-weight-bold;
  z-index: 2;
  transition-property: 'background-color';
  transition-duration: 200ms;
  &__active {
    background-color: $color-primary;
    color: $color-white;
  }
  &:last-child {
    margin-right: 0;
  }
}
</style>
