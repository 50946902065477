<template>
  <div>
    <p class="text-strong">
      {{ $t('FORM.COMPLETE_YOUR_APPLICATION') }}
    </p>
    <b-row class="margins__double">
      <b-col>
        <div
          v-for="(promo, index) in ONLINE_APPLICATION_PROMOS"
          :key="`${promo.header}-${index}`"
        >
          <p>
            <span class="text-strong">{{ $t(promo.header) }}:</span>
            {{ $t(promo.text) }}.
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row class="margins__double">
      <b-col md="3">
        <Input
          v-model.trim="form.mobilePhoneNumberCode"
          :label="`${$t('FORM.MOBILE_NUMBER')} *`"
          name="mobilePhoneNumberCode"
          :maxlength="6"
          :error="errors.first('mobilePhoneNumberCode')"
          prefix="🇩🇪"
          v-validate="{
            required: true,
            numeric: true,
            regex: REGEX.DE.mobileNumberCode,
          }"
          :data-vv-as="$t('FORM.VALIDATOR.MOBILE_CODE')"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="3" class="mt-3 pt-1">
        <Input
          name="mobilePhoneNumber"
          v-model.trim="form.mobilePhoneNumber"
          :error="errors.first('mobilePhoneNumber')"
          v-validate="{
            required: true,
            numeric: true,
            regex: REGEX.DE.mobileNumberExtension,
          }"
          :data-vv-as="$t('FORM.VALIDATOR.MOBILE_EXTENSION')"
          class="margins__mobile-md-double bold"
        />
      </b-col>

      <div class="py-md-3 px-md-3 margins__mobile-md-double">
        <i18n path="FORM.MOBILE_NUMBER_HINT" tag="p">
          <template #hint>
            <span class="text-strong"> {{ $t('FORM.HINT') }}: </span>
          </template>
        </i18n>
      </div>
      <div class="py-md-3 px-md-3 margins__mobile-md-double">
        <p class="text-grey text-small">{{ $t('FORM.CONSENTS_DISCLAIMER') }}</p>
      </div>
      <div class="py-md-3 px-md-3 margins__mobile-md-double">
        <Check
          v-model="form.phonePublicityConsent"
          name="phonePublicityConsent"
          v-validate="'required: true'"
          :error="errors.first('phonePublicityConsent')"
          class="margins__mobile-md-double"
        >
          <template #label>
            <i18n path="FORM.PHONE_PUBLICITY_CONSENT" tag="p">
              <template #phone>
                <a href="tel:+4921102033475847"> 0203 - 34 75 847 </a>
              </template>
              <template #fax>
                <a href="tel:+49211020334716071"> 0203 - 34 71 60 71 </a>
              </template>
              <template #email>
                <a href="mailto:kontakt@targobank.de"> kontakt@targobank.de </a>
              </template>
            </i18n>
          </template>
        </Check>
      </div>
      <div class="pb-md-3 px-md-3 margins__mobile-md-double">
        <Check
          v-model="form.privacyConsent"
          name="privacyConsent"
          :error="errors.first('privacyConsent')"
          v-validate="'required: true'"
          class="margins__mobile-md-double"
        >
          <template #label>
            <i18n path="FORM.PRIVACY_CONSENT" tag="p">
              <template #termsAndConditions>
                <a
                  :href="`${window.location.origin}/documents/GeneralTerms.pdf`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="bold"
                >
                  <span>{{ $t('FORM.TERMS_AND_CONDITIONS') }}</span>
                </a>
              </template>
              <template #personalDataLink>
                <a
                  :href="dataProtection"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="bold"
                >
                  {{ $t('FORM.PRIVACY_CONSENT_HERE') }}
                </a>
              </template>
              <template #revocationLink>
                <a
                  :href="`${window.location.origin}/documents/GeneralTerms.pdf`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="bold"
                >
                  <span>{{ $t('FORM.PRIVACY_CONSENT_HERE') }}</span>
                </a>
              </template>
            </i18n>
          </template>
        </Check>
      </div>
    </b-row>
    <p class="text-center text-grey mt-4">{{ $t('FORM.DISCLAIMER') }}</p>
    <slot :validate="validate" :form="form" />
  </div>
</template>
<script>
import { Check, Input } from '@/components';
import { constants, createOptions } from '@/mixins';
import { ONLINE_APPLICATION_PROMOS } from '@/constants';

export default {
  name: 'ApplicantPersonalPhoneData',
  mixins: [constants, createOptions],
  components: {
    Input,
    Check,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {},
      ONLINE_APPLICATION_PROMOS,
    };
  },
  created() {
    this.form = {
      ...this.formData,
      ...this.form,
      privacyConsent: null,
      phonePublicityConsent: null,
    };
  },
  mounted() {
    this.scrollToTop();
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    async validate() {
      const valid = await this.$validator.validateAll();
      return valid;
    },
  },
  computed: {
    policyFileName() {
      return `FairownPrivacyPolicy${this.$i18n.locale.toUpperCase()}`;
    },
    dataProtection() {
      return `${window.location.origin}/documents/Datenschutzerklärung.pdf`;
    },
  },
};
</script>
