export const cookieInfoTableHeaders = [
  'COOKIES',
  'COOKIE_GROUP',
  'VALIDITY',
  'USED_COOKIES',
  'DESCRIPTION',
];

export const tableTypes = ['necessary', 'marketing'];

export const tablesContent = {
  necessary: [
    {
      COOKIES: 'cookie_consent',
      COOKIE_GROUP: 'Fairown',
      VALIDITY: '1 Jahr',
      USED_COOKIES: 'First Party Cookie',
      DESCRIPTION:
        'Dieses Cookie speichert die Einwilligung in Cookies auf der Website.',
    },
    {
      COOKIES: 'SESSION',
      COOKIE_GROUP: 'Fairown',
      VALIDITY: 'Sitzung',
      USED_COOKIES: 'First Party Cookie',
      DESCRIPTION:
        'Sitzungscookies ermöglichen die Wiedererkennung von Benutzern innerhalb einer Website, so dass jeder Seitenwechsel oder jede Auswahl von Elementen oder Daten, die Sie vornehmen, von Seite zu Seite gespeichert wird.',
    },
  ],
  marketing: [
    {
      COOKIES: '1P_JAR',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '1 Monat',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von Google verwendet, um Anzeigenschaltung oder Retargeting zu ermöglichen.',
    },
    {
      COOKIES: 'AEC',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '6 Monate',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von Google verwendet, um Spam, Betrug und Missbrauch zu verhindern.',
    },
    {
      COOKIES: 'APISID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von Google verwendet, um Anzeigenschaltung oder Retargeting zu ermöglichen.',
    },
    {
      COOKIES: 'CONSENT',
      COOKIE_GROUP: 'youtube.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieser Cookie speichert Informationen über die vom Benutzer gewählten Cookies.',
    },
    {
      COOKIES: 'DSID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '14 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von Google verwendet, um Nutzerpräferenzen bezüglich der Anzeigenpersonalisierung zu speichern.',
    },
    {
      COOKIES: 'DV',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '1 Tag',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von Google verwendet, um Anzeigenschaltung oder Retargeting zu ermöglichen.',
    },
    {
      COOKIES: 'HSID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von Google verwendet, um Spam, Betrug und Missbrauch zu verhindern.',
    },
    {
      COOKIES: 'IDE',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von Google verwendet, um Anzeigenschaltung oder Retargeting zu ermöglichen.',
    },
    {
      COOKIES: 'NID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '6 Monate',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von Google verwendet, um Anzeigenschaltung oder Retargeting zu ermöglichen und Nutzerpräferenzen zu speichern.',
    },
    {
      COOKIES: 'OTZ',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von Google verwendet, um eine aggregierte Analyse der Website-Besucher zu erstellen.',
    },
    {
      COOKIES: 'SAPISID',
      COOKIE_GROUP: 'youtube.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von YouTube verwendet, um auf der Website eingebettete YouTube-Videos abzuspielen.',
    },
    {
      COOKIES: 'SID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Sicherheits-Cookie zur Bestätigung der Authentizität des Besuchers, zur Verhinderung der betrügerischen Verwendung von Anmeldedaten und zum Schutz der Besucherdaten vor unberechtigtem Zugriff.',
    },
    {
      COOKIES: 'SIDCC',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '3 Monate',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Sicherheits-Cookie zur Bestätigung der Authentizität des Besuchers, zur Verhinderung der betrügerischen Verwendung von Anmeldedaten und zum Schutz der Besucherdaten vor unberechtigtem Zugriff.',
    },
    {
      COOKIES: 'SSID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '364 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie enthält Informationen darüber, wie der Endnutzer die Website nutzt und welche Werbung der Endnutzer vor dem Besuch der Website möglicherweise gesehen hat.',
    },
    {
      COOKIES: 'VISITOR_INFO1_LIVE',
      COOKIE_GROUP: 'youtube.com',
      VALIDITY: '6 Monate',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von YouTube verwendet, um Bandbreitenschätzungen zu liefern.',
    },
    {
      COOKIES: 'YSC',
      COOKIE_GROUP: 'youtube.com',
      VALIDITY: 'Sitzung',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird von YouTube verwendet, um Interaktionen zu speichern und zu verfolgen.',
    },
    {
      COOKIES: '_Secure-1PAPISID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Targeting-Cookie. Wird für Targeting-Zwecke verwendet, um ein Profil der Interessen des Website-Besuchers zu erstellen, damit relevante und personalisierte Google-Werbung angezeigt werden kann.',
    },
    {
      COOKIES: '_Secure-1PSID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Targeting-Cookie. Wird für Targeting-Zwecke verwendet, um ein Profil der Interessen des Website-Besuchers zu erstellen, damit relevante und personalisierte Google-Werbung angezeigt werden kann.',
    },
    {
      COOKIES: '_Secure-1PSIDCC',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Targeting-Cookie. Wird für Targeting-Zwecke verwendet, um ein Profil der Interessen des Website-Besuchers zu erstellen, damit relevante und personalisierte Google-Werbung angezeigt werden kann.',
    },
    {
      COOKIES: '_Secure-3PAPISID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Erstellt ein Profil der Interessen von Website-Besuchern, um durch Retargeting relevante und personalisierte Werbung anzuzeigen.',
    },
    {
      COOKIES: '_Secure-3PSID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Erstellt ein Profil der Interessen von Website-Besuchern, um durch Retargeting relevante und personalisierte Werbung anzuzeigen.',
    },
    {
      COOKIES: '_Secure-3PSIDCC',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '729 Tage',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Targeting-Cookie. Wird für Targeting-Zwecke verwendet, um ein Profil der Interessen des Website-Besuchers zu erstellen, damit relevante und personalisierte Google-Werbung angezeigt werden kann.',
    },
    {
      COOKIES: '_Secure-ENID',
      COOKIE_GROUP: 'google.com',
      VALIDITY: '13 Monate',
      USED_COOKIES: 'Third Party Cookie',
      DESCRIPTION:
        'Dieses Cookie wird verwendet, um Ihre Präferenzen und andere Informationen zu speichern, z. B. Ihre bevorzugte Sprache, die Anzahl der Ergebnisse, die Sie auf einer Suchergebnisseite anzeigen lassen möchten (z. B. 10 oder 20) und ob Sie den SafeSearch-Filter von Google aktivieren möchten.',
    },
  ],
};

export const consentsDescriptions = {
  necessary: [
    {
      title:
        'COOKIE_INFO.MODAL.TABLE.COOKIES.FIRST_PARTY.TABLE_DESCRIPTION.TITLE',
      text: 'COOKIE_INFO.MODAL.TABLE.COOKIES.FIRST_PARTY.TABLE_DESCRIPTION.TEXT',
    },
  ],
  marketing: [
    {
      title:
        'COOKIE_INFO.MODAL.TABLE.COOKIES.THIRD_PARTY.TABLE_DESCRIPTION.TITLE',
      text: 'COOKIE_INFO.MODAL.TABLE.COOKIES.THIRD_PARTY.TABLE_DESCRIPTION.TEXT',
    },
  ],
};
