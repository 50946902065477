var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-store-list"},[_c('Input',{directives:[{name:"validate",rawName:"v-validate",value:({
      regex: _vm.REGEX.DE.postalCode,
    }),expression:"{\n      regex: REGEX.DE.postalCode,\n    }"}],attrs:{"name":"postalCode","label":_vm.$t('MAP.ZIP_CODE'),"placeholder":_vm.$t('MAP.ZIP_CODE_PLACEHOLDER'),"error":_vm.errors.first('postalCode'),"data-vv-as":_vm.$t('MAP.VALIDATOR.POSTAL_CODE')},on:{"input":_vm.onZipInput},model:{value:(_vm.zip),callback:function ($$v) {_vm.zip=$$v},expression:"zip"}}),_c('transition',{attrs:{"name":"fade-router","mode":"out-in"}},[(_vm.geocoderError)?_c('span',{staticClass:"input__error"},[_vm._v(" "+_vm._s(_vm.$t(`MAP.ERRORS.${_vm.geocoderError}`))+" ")]):_vm._e()]),_c('ul',{ref:"list",class:[
      'select-store-list__list',
      {
        'select-store-list__list--fade-top': !_vm.isScrolledToListTop,
        'select-store-list__list--fade-bottom': !_vm.isScrolledToListBottom,
      },
    ]},_vm._l((_vm.stores),function(store,index){return _c('li',{key:index},[_c('Button',{attrs:{"variant":"text","active":_vm.selectedStore === index,"disabled":!_vm.isAvailable(store.id)},on:{"click":function($event){return _vm.chooseStore(store)}},nativeOn:{"mouseover":function($event){return _vm.hoverStore(index)},"focus":function($event){return _vm.hoverStore(index)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('MapMarkerSVG',{staticClass:"icon icon--small text-grey"})]},proxy:true}],null,true)},[_c('div',[_c('p',[_vm._v(" "+_vm._s(store.name)+" ")]),(store.distance && store.duration)?_c('p',{staticClass:"text-strong"},[_vm._v(" "+_vm._s(_vm.$t('MAP.APPROXIMATE_DISTANCE', [ store.distance.text, store.duration, ]))+" ")]):_vm._e()]),(!_vm.isAvailable(store.id))?_c('span',{staticClass:"button__text-small text-small text-grey"},[_vm._v(" ("+_vm._s(_vm.$t('NOT_AVAILABLE'))+") ")]):_vm._e()])],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }