<template>
  <div class="absolut">
    <h6>{{ $t('FORM.YOUR_PROFESSION') }}</h6>
    <b-row class="margins__one">
      <b-col md="6">
        <InputField
          v-model.trim="form.employmentJobTitle"
          name="employmentJobTitle"
          :error="errors.first('employmentJobTitle')"
          :maxlength="40"
          v-validate="{ required: true, regex: REGEX.DE.jobTitle }"
          :data-vv-as="$t('FORM.VALIDATOR.JOB_TITLE')"
          class="margins__mobile-md-double"
        >
          <template #label>
            <Tooltip target="jobTitle" container="body">
              <template #parent>
                <span id="jobTitle" class="inline">
                  {{ $t('FORM.JOB_TITLE') }} *
                </span>
              </template>
              <template #default>
                <span> {{ $t('FORM.JOB_TITLE_TOOLTIP') }} </span>
              </template>
            </Tooltip>
          </template>
        </InputField>
      </b-col>
      <b-col md="6">
        <Select
          v-model.trim="form.employmentIndustry"
          :label="`${$t('FORM.OCCUPATION_INDUSTRY')} *`"
          name="employmentIndustry"
          :options="OCCUPATION_INDUSTRY"
          :error="errors.first('employmentIndustry')"
          v-validate="{ required: true }"
          :data-vv-as="$t('FORM.VALIDATOR.OCCUPATION_INDUSTRY')"
          class="margins__mobile-md-double"
        >
          <template #default="{ option }">{{ $t(option.label) }}</template>
        </Select>
      </b-col>
    </b-row>
    <b-row class="margins__one">
      <b-col md="6">
        <Select
          v-model.trim="form.employmentOccupationalGroup"
          name="employmentOccupationalGroup"
          :options="OCCUPATION_TYPE"
          :error="errors.first('employmentOccupationalGroup')"
          v-validate="{ required: true }"
          :data-vv-as="$t('FORM.VALIDATOR.OCCUPATION_GROUP')"
          class="margins__mobile-md-double"
        >
          <template #label>
            <Tooltip target="occupationGroup" container="body">
              <template #parent>
                <span id="occupationGroup">
                  {{ $t('FORM.OCCUPATION_GROUP') }} *
                </span>
              </template>
              <template #default>
                <span class="p-3">
                  {{ $t('FORM.OCCUPATION_GROUP_TOOLTIP') }}
                </span>
              </template>
            </Tooltip>
          </template>

          <template #default="{ option }">{{ $t(option.label) }}</template>
        </Select>
      </b-col>
      <b-col md="6">
        <InputField
          v-model.trim="form.employerName"
          :label="`${$t('FORM.EMPLOYER_NAME')} *`"
          name="employerName"
          :maxlength="40"
          :error="errors.first('employerName')"
          v-validate="{ required: true, regex: REGEX.DE.employerName }"
          :data-vv-as="$t('FORM.VALIDATOR.EMPLOYER_NAME')"
          class="margins__mobile-md-double"
        />
      </b-col>
    </b-row>

    <b-row class="margins__one">
      <b-col md="6">
        <InputField
          v-model.trim="form.employmentTown"
          :label="`${$t('FORM.EMPLOYER_LOCATION')} *`"
          name="employmentTown"
          :placeholder="`${$t('FORM.PLACEHOLDER.EMPLOYER_LOCATION')}`"
          :maxlength="35"
          :error="errors.first('employmentTown')"
          v-validate="{ required: true, regex: REGEX.DE.employerLocation }"
          :data-vv-as="$t('FORM.VALIDATOR.EMPLOYER_LOCATION')"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="6">
        <CustomDatePicker
          v-model.trim="form.employmentJobSince"
          :placeholder="`${$t('FORM.PLACEHOLDER.CALENDAR')}`"
          name="employmentJobSince"
          :error="errors.first('employmentJobSince')"
          v-validate="{ required: true }"
          :data-vv-as="$t('FORM.VALIDATOR.EMPLOYMENT_SINCE')"
          :locale="$i18n.locale"
          :masks="masks"
          class="margins__mobile-md-double"
          :max-date="maxDate"
        >
          <template #label>
            <Tooltip target="employmentJobSince">
              <template #parent>
                <span id="employmentJobSince" class="inline">
                  {{ $t('FORM.EMPLOYMENT_SINCE') }} *
                </span>
              </template>
              <template #default>
                <span> {{ $t('FORM.EMPLOYMENT_SINCE_TOOLTIP') }} </span>
              </template>
            </Tooltip>
          </template>
        </CustomDatePicker>
      </b-col>
    </b-row>
    <slot :validate="validate" :form="form" />
  </div>
</template>
<script>
import { constants, createOptions } from '@/mixins';
import {
  Input as InputField,
  Select,
  CustomDatePicker,
  Tooltip,
} from '@/components';
import { OCCUPATION_TYPE, OCCUPATION_INDUSTRY } from '@/constants';

export default {
  name: 'ApplicantProfessionData',
  mixins: [constants, createOptions],
  components: {
    InputField,
    Select,
    CustomDatePicker,
    Tooltip,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {},
      OCCUPATION_TYPE,
      OCCUPATION_INDUSTRY,
    };
  },
  created() {
    this.form = { ...this.formData };
  },
  mounted() {
    this.scrollToTop();
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    async validate() {
      const valid = await this.$validator.validateAll();
      return valid;
    },
  },
  computed: {
    masks() {
      return { input: 'DD/MM/YYYY' };
    },
    maxDate() {
      return new Date();
    },
  },
};
</script>
