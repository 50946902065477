<template>
  <div class="table-responsive">
    <table class="table">
      <slot />
    </table>
  </div>
</template>
<script>
export default {
  name: 'Table',
};
</script>
