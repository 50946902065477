<template>
  <div>
    <h6>{{ $t('FORM.YOUR_INSTALLMENT_PAYMENT') }}</h6>
    <p class="text-grey text-small mt-4">
      {{ $t('FORM.ACCOUNT_MONTHLY_PAYMENT') }}
    </p>
    <b-row class="margins__one">
      <b-col md="4">
        <InputField
          v-model.trim="form.iban"
          :label="`${$t('FORM.IBAN')} *`"
          name="iban"
          :error="errors.first('iban')"
          v-validate="{
            required: true,
            regex: REGEX.DE.iban,
            max: 22,
            isIban: true,
          }"
          :data-vv-as="$t('FORM.VALIDATOR.IBAN')"
          class="margins__mobile-md-double"
          @change="formatIban"
        >
          <template #error>
            {{ errors.first('iban') }}
          </template>
        </InputField>
      </b-col>
      <div class="py-md-3 px-md-3 margins__mobile-md-double">
        <Check
          v-model="form.schufaConsent"
          name="schufaConsent"
          v-validate="'required: true'"
          :error="errors.first('schufaConsent')"
          class="margins__mobile-md-double"
        >
          <template #label>
            <i18n path="FORM.TARGO_CONSENT" tag="p">
              <template #targoConsent>
                <a
                  :href="`${window.location.origin}/documents/${targoPolicyFileName}.pdf`"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ $t('FORM.TARGO_PROTECTION_DATA') }}
                </a>
              </template>
            </i18n>
          </template>
        </Check>
      </div>
    </b-row>
    <slot :validate="validate" :form="form" />
  </div>
</template>
<script>
import { Check, Input as InputField } from '@/components';

import { constants, createOptions } from '@/mixins';

export default {
  name: 'ApplicantIBANData',
  mixins: [constants, createOptions],
  components: {
    InputField,
    Check,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {},
    };
  },
  created() {
    this.form = { ...this.formData, ...this.form, schufaConsent: null };
  },
  mounted() {
    this.scrollToTop();
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    async validate() {
      const valid = await this.$validator.validateAll();
      return valid;
    },

    formatIban() {
      this.form.iban = this.form.iban.toUpperCase();
      const valid = this.$validator.validate('iban', this.form.iban);
      return valid;
    },
  },
  computed: {
    targoPolicyFileName() {
      return `Datenschutzhinweise_der_TARGOBANK_TKE_Version`;
    },
  },
};
</script>
