<template>
  <Card color="grey" padding="medium">
    <p class="text-strong pb-3">
      {{ $t('FORM.DOCUMENT_NUMBER_TOOLTIP.HEADER') }}
    </p>

    <b-row align-v="center" align-h="center">
      <b-col md="6">
        <div class="margins__mobile-md-double">
          <p>
            {{ $t('FORM.DOCUMENT_NUMBER_TOOLTIP.FIRST_CASE.DATE_OF_DOCUMENT') }}
          </p>
          <p class="p-2">
            {{ $t('FORM.DOCUMENT_NUMBER_TOOLTIP.FIRST_CASE.PART_TO_ENTER') }}
          </p>
          <img class="lazyload" src="@/assets/images/perso1.png" alt="" />
        </div>
      </b-col>
      <b-col md="6">
        <div class="margins__mobile-md-double">
          <p>
            {{
              $t('FORM.DOCUMENT_NUMBER_TOOLTIP.SECOND_CASE.DATE_OF_DOCUMENT')
            }}
          </p>
          <p class="py-2">
            {{ $t('FORM.DOCUMENT_NUMBER_TOOLTIP.SECOND_CASE.PART_TO_ENTER') }}
          </p>
          <img class="lazyload" src="@/assets/images/perso2.png" alt="" />
        </div>
      </b-col>
    </b-row>
  </Card>
</template>

<script>
import { Card } from '@/components';
export default {
  name: 'PersonalDataTooltip',
  components: {
    Card,
  },
};
</script>
