<template>
  <header class="header">
    <Container class="header__container">
      <b-row no-gutters>
        <b-col cols="auto">
          <router-link
            :to="ROUTES.HOME.path"
            :title="$t('ROUTER.DEFAULT_TITLE')"
            class="header__main-link"
          >
            <StihlLogo class="header__logo" />
          </router-link>
        </b-col>
        <b-col>
          <ul class="header__list">
            <li
              v-for="item in headerItems"
              :key="item.label"
              class="header__list-item"
            >
              <router-link
                :to="item.to"
                :exact="item.exact"
                :event="item.event"
                :class="item.class"
                :title="item.label"
              >
                {{ item.label }}
              </router-link>
            </li>
            <li class="header__list-item">
              <router-link
                :to="ROUTES.FAQ.path"
                target="_blank"
                class="header__link is-valid"
              >
                {{ $t('FAQ.LINK_TEXT') }}
              </router-link>
            </li>
          </ul>
        </b-col>
        <b-col v-if="multipleLanguages" cols="auto" align-self="center">
          <LanguageSwitcher />
        </b-col>
      </b-row>
    </Container>
  </header>
</template>

<script>
import { Container } from '@/components';
import { mapState } from 'vuex';
import { constants } from '@/mixins';
import LanguageSwitcher from './LanguageSwitcher.vue';
import StihlLogo from '@/assets/images/stihl-logo.svg';

export default {
  name: 'Header',
  mixins: [constants],
  components: {
    Container,
    LanguageSwitcher,
    StihlLogo,
  },
  computed: {
    ...mapState(['currentStep', 'isLoading']),
    headerItems() {
      return [
        {
          label: `${this.$t('HEADER.HOME')}`,
          to: this.ROUTES.SELECT_BUNDLE.path,
          exact: true,
          event: this.isLoading ? '' : 'click',
          class: ['header__link', { 'is-disabled': this.isLoading }],
        },
      ];
    },
    multipleLanguages() {
      return this.STORE.availableLocales.length > 1;
    },
  },
};
</script>

<style lang="scss">
.header {
  background: $color-white;
  border-bottom: rem(1px) solid $color-grey-dark;
}

.header__container {
  padding-left: 0;
  padding-right: 0;

  @media (min-width: $container-width) {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
}

.header__main-link {
  display: flex;
  align-items: center;
  height: 100%;
}

.header__logo {
  height: $header-logo-height;
  width: auto;

  @include min-width(md) {
    height: $header-logo-height-desktop;
  }
}

.header__list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
}

.header__list-item {
  display: flex;
  align-items: center;
  height: 100%;
}

.header__list-item + .header__list-item {
  border-left: rem(1px) solid $color-grey-dark;
}

.header__link {
  display: flex;
  align-items: center;
  height: 100%;
  color: $color-black;
  border-left: 0;
  border-right: 0;
  font-family: $font-family-header;
  font-weight: $header-link-font-weight;
  font-size: $font-size-extra-small;
  letter-spacing: $header-link-letter-spacing;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 100%;
  min-height: 2.5rem;
  white-space: nowrap;

  @include min-width(sm) {
    font-size: $header-link-font-size;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &:hover {
    text-decoration: none;
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.5;
  }
}
</style>
