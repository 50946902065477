<template>
  <div class="select-store-map">
    <div class="select-store-map__map">
      <GmapMap
        ref="mapRef"
        :center="center || mapConfig.centerPosition"
        :zoom="mapConfig.zoom"
        :options="{
          styles: mapStyles,
        }"
        class="map"
      >
        <GmapMarker
          v-for="(store, index) in stores"
          :key="index"
          :position="{ lat: store.latitude, lng: store.longitude }"
          :clickable="true"
          :title="store.name"
          :icon="getAvailableIcon()"
          :ref="`marker-${index}`"
          @click="openInfoWindow(index, store)"
        />
        <GmapInfoWindow
          :options="infoWindowConfig.options"
          :position="infoWindowConfig.position"
          :opened="infoWindowConfig.open"
          @closeclick="closeInfoWindow"
        />
        <GmapMarker
          v-if="Object.keys(selectedPosition).length"
          :position="{
            lat: selectedPosition.latitude,
            lng: selectedPosition.longitude,
          }"
          :icon="getZipLocationIcon()"
          :title="selectedPosition.title"
        />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import { mapStyles } from '@/utils';
import { constants } from '@/mixins';
import { mapState, mapMutations } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import { SET_STORES, SET_GEOCODER_ERROR } from '@/types';

export default {
  name: 'SelectStoreMap',
  mixins: [constants],
  props: {
    hoveredStore: Number,
    stores: Array,
    view: Boolean,
    zip: String,
  },
  watch: {
    hoveredStore: function (newIndex, oldIndex) {
      this.stopAnimation(oldIndex);
      this.startAnimation(newIndex);

      if (this.view) {
        this.openInfoWindow(newIndex, this.stores[newIndex]);
      }
    },
    zip: function () {
      this.codeAddress();
    },
  },
  data() {
    return {
      selectedPosition: {},
      center: null,
      infoWindowConfig: {
        options: {
          content: '',
          pixelOffset: {
            width: 0,
            height: -35,
          },
        },
        open: false,
        position: {
          lat: 0,
          lng: 0,
        },
      },
    };
  },
  mounted() {
    document.addEventListener('click', (e) => {
      const target = e.target;

      if (target.className.includes('js-choose-store')) {
        const store = JSON.parse(target.dataset.store);

        this.chooseStore(store);
      }
    });
  },
  methods: {
    ...mapMutations([SET_STORES, SET_GEOCODER_ERROR]),
    getZipLocationIcon() {
      return 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyMSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjI1NTIgMjguMTE2NEMxMy4yMjA1IDI1Ljk5NSAyMC41NTQxIDE3LjU3OTIgMjAuNTU0MSAxMC4xMDUzQzIwLjU1NDEgNy41MTE5NCAxOS41MjQgNS4wMjQ1NSAxNy42OTAyIDMuMTkwODNDMTUuODU2MiAxLjM1NjgzIDEzLjM2OSAwLjMyNjY2IDEwLjc3NTcgMC4zMjY2NkM4LjE4MjQ1IDAuMzI2NjYgNS42OTUwMSAxLjM1NjgzIDMuODYxMjkgMy4xOTA4M0MyLjAyNzUxIDUuMDI0NiAwLjk5NzM0NSA3LjUxMTk5IDAuOTk3MzQ1IDEwLjEwNTNDMC45OTczNDUgMTcuNTc5MiA4LjMzMDY4IDI1Ljk5NTMgMTAuMjk2MiAyOC4xMTY0SDEwLjI5NkMxMC40MTk3IDI4LjI1MDUgMTAuNTkzNSAyOC4zMjY3IDEwLjc3NTggMjguMzI2N0MxMC45NTgxIDI4LjMyNjcgMTEuMTMyIDI4LjI1MDUgMTEuMjU1NiAyOC4xMTY0SDExLjI1NTJaTTUuNjc4NTcgMTAuMTA1M0M1LjY3ODU3IDguNzUzNzEgNi4yMTU0NiA3LjQ1NzI3IDcuMTcxMTggNi41MDE1NUM4LjEyNzEyIDUuNTQ1NiA5LjQyMzM0IDUuMDA4NzEgMTAuNzc1MSA1LjAwODcxQzEyLjEyNjkgNS4wMDg3MSAxMy40MjMxIDUuNTQ1NjEgMTQuMzc5MSA2LjUwMTU1QzE1LjMzNDggNy40NTcyNyAxNS44NzE3IDguNzUzNzEgMTUuODcxNyAxMC4xMDUzQzE1Ljg3MTcgMTEuNDU3IDE1LjMzNDggMTIuNzUzNSAxNC4zNzkxIDEzLjcwOTJDMTMuNDIzMSAxNC42NjQ5IDEyLjEyNjkgMTUuMjAyIDEwLjc3NTEgMTUuMjAyQzkuNDIzMzQgMTUuMjAyIDguMTI3MTIgMTQuNjY0OSA3LjE3MTE4IDEzLjcwOTJDNi4yMTU0NiAxMi43NTM1IDUuNjc4NTcgMTEuNDU3IDUuNjc4NTcgMTAuMTA1M1oiIGZpbGw9IiNGNjdBMjAiLz4KPC9zdmc+Cg==';
    },
    getAvailableIcon() {
      return 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyOHB4IiB2aWV3Qm94PSIwIDAgMTQgMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjUgKDY3NDY5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29uL1BpbjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29uL1BpbiIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Im1hcHMtYW5kLWZsYWdzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC4wMDAwMDAsIDAuMDAwMDAwKSIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTYuNiwwIEMyLjk2MDc0MDM1LDAgMCwyLjkyNDM3MDc1IDAsNi41MTg4OTA3NiBDMCwxMC45Nzk4MDI0IDUuOTA2MzUzNDQsMTcuNTI4NjgxNSA2LjE1NzgyMTY4LDE3LjgwNTI5MTIgQzYuMzk0MDIwMjksMTguMDY1MTMxNCA2LjgwNjQwNjgzLDE4LjA2NDY3NDQgNy4wNDIxNzgzMiwxNy44MDUyOTEyIEM3LjI5MzY0NjU2LDE3LjUyODY4MTUgMTMuMiwxMC45Nzk4MDI0IDEzLjIsNi41MTg4OTA3NiBDMTMuMTk5OTI4OCwyLjkyNDM3MDc1IDEwLjIzOTIyNDEsMCA2LjYsMCBaIE02LjYsOS43OTg3MjY3OCBDNC43Njg5OTgwNCw5Ljc5ODcyNjc4IDMuMjc5NDA5MTUsOC4zMjc0MDA3NyAzLjI3OTQwOTE1LDYuNTE4ODkwNzYgQzMuMjc5NDA5MTUsNC43MTAzODA3NCA0Ljc2OTAzMzY0LDMuMjM5MDg5ODggNi42LDMuMjM5MDg5ODggQzguNDMwOTY2MzYsMy4yMzkwODk4OCA5LjkyMDU1NTI2LDQuNzEwNDE1OSA5LjkyMDU1NTI2LDYuNTE4OTI1OTEgQzkuOTIwNTU1MjYsOC4zMjc0MzU5MyA4LjQzMDk2NjM2LDkuNzk4NzI2NzggNi42LDkuNzk4NzI2NzggWiIgaWQ9IlNoYXBlIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K';
    },
    getOutOfStockIcon() {
      return 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxNCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi42IDBDMi45NiAwIDAgMi45MjQgMCA2LjUxOWMwIDQuNDYgNS45MDYgMTEuMDEgNi4xNTggMTEuMjg2YS42LjYgMCAwIDAgLjg4NCAwQzcuMjk0IDE3LjUzIDEzLjIgMTAuOTggMTMuMiA2LjUyIDEzLjIgMi45MjQgMTAuMjQgMCA2LjYgMHptMCA5Ljc5OWMtMS44MzEgMC0zLjMyLTEuNDcyLTMuMzItMy4yOCAwLTEuODA5IDEuNDg5LTMuMjggMy4zMi0zLjI4IDEuODMxIDAgMy4zMiAxLjQ3MSAzLjMyIDMuMjggMCAxLjgwOC0xLjQ4OSAzLjI4LTMuMzIgMy4yOHoiIGZpbGw9IiNhNmE2YTYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==';
    },
    getMarkerObject(index) {
      const markerRef = this.$refs[`marker-${index}`][0];

      if (!markerRef) {
        return false;
      }

      const marker = markerRef.$markerObject;

      if (!marker) {
        return false;
      }

      return marker;
    },
    stopAnimation(index) {
      if (index === null) {
        return false;
      }

      const marker = this.getMarkerObject(index);

      if (!marker) {
        return false;
      }

      marker.setAnimation(null);

      this.$emit('resetIndex', null);
    },
    startAnimation(index) {
      if (index === null) {
        return false;
      }

      const marker = this.getMarkerObject(index);

      if (!marker) {
        return false;
      }

      if (marker.animating) {
        this.stopAnimation(index);
      }

      marker.setAnimation(window.google.maps.Animation.BOUNCE);

      setTimeout(() => {
        this.stopAnimation(index);
      }, 2000);
    },
    openInfoWindow(index, store) {
      this.selectStore(index);

      if (this.$refs[`marker-${index}`]) {
        const marker = this.$refs[`marker-${index}`][0].$markerObject;

        this.infoWindowConfig.position = marker.position;
        this.infoWindowConfig.options.content = `
        <div class="select-store-map__store-info">
          <button
            type="button"
            class="button button--text button--small button--map js-choose-store"
            ${this.view ? 'disabled' : null}
            data-store='${JSON.stringify(store)}'>
              <strong>${store.name}</strong>
              ${
                (store.fullAddress
                  ? `<br />
                  <span>${store.fullAddress}</span>`
                  : '') +
                (store.phoneNumber
                  ? `<br />
                  <span>${store.phoneNumber}</span>`
                  : '')
              }
          </button>
          ${
            store.contactEmail
              ? `<a href="mailto:${store.contactEmail}" class="store-mail-link">${store.contactEmail}</a>`
              : ''
          }
        </div>
      `;

        this.infoWindowConfig.open = true;
      }
    },
    closeInfoWindow() {
      this.infoWindowConfig.open = false;

      this.$emit('selectStore', null);
    },
    selectStore(index) {
      this.$emit('selectStore', index);
    },
    chooseStore(store) {
      this.$emit('chooseStore', store);
    },
    codeAddress() {
      this.selectedPosition = {};
      this.$refs.mapRef.$mapPromise.then(() => {
        const map = this.$refs.mapRef;
        const geocoder = new this.google.maps.Geocoder();
        geocoder
          .geocode({
            address: '',
            componentRestrictions: {
              country: 'DE',
              postalCode: this.zip,
            },
          })
          .then(({ results }) => {
            this.SET_GEOCODER_ERROR(null);
            this.center = results[0].geometry.location;
            map.panTo(results[0].geometry.location);
            const pt = new this.google.maps.LatLng(
              results[0].geometry.location.lat(),
              results[0].geometry.location.lng(),
            );
            this.selectedPosition = {
              latitude: results[0].geometry.location.lat() || '',
              longitude: results[0].geometry.location.lng() || '',
            };
            this.closest = this.findClosestN(pt, 5);
            // get driving distance
            this.hydrateStoresWithDrivingDistance(pt, this.closest);
          })
          .catch((error) => {
            this.SET_GEOCODER_ERROR(error.code);
          });
      });
    },
    findClosestN(pt, numberOfResults) {
      return this.stores
        .map((store) => {
          const place = new this.google.maps.Marker({
            position: new this.google.maps.LatLng(
              store.latitude,
              store.longitude,
            ),
            address: store.address,
            title: store.name,
          });
          place.distance =
            this.google.maps.geometry.spherical.computeDistanceBetween(
              pt,
              place.getPosition(),
            );
          return place;
        })
        .sort((a, b) => a.distance - b.distance)
        .splice(0, numberOfResults);
    },
    async hydrateStoresWithDrivingDistance(pt, closest) {
      const promisedDistances = await this.calculateDistances(pt, closest);
      const places = promisedDistances.map((place) => {
        const store = this.stores.find((store) => store.name === place.title);
        return {
          ...store,
          ...{ distance: place.distance, duration: place.duration },
        };
      });

      this.SET_STORES(places);
    },
    async calculateDistances(pt, closest) {
      const service = new this.google.maps.DistanceMatrixService();
      const matrixStatus = this.google.maps.DistanceMatrixStatus;
      const request = {
        origins: [pt],
        destinations: closest.map((c) => c.getPosition()),
        travelMode: this.google.maps.TravelMode.DRIVING,
        unitSystem: this.google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      };

      return new Promise((resolve, reject) => {
        service.getDistanceMatrix(request, function (response, status) {
          if (status === matrixStatus.OK) {
            const places = closest.map((place, index) => ({
              ...place,
              distance: response.rows[0].elements[index].distance,
              duration: response.rows[0].elements[index].duration.text,
            }));

            resolve(places);
          } else {
            reject();
          }
        });
      });
    },
  },
  computed: {
    ...mapState(['selectedBundle']),
    google: gmapApi,
    mapConfig() {
      return this.STORE.mapConfig;
    },
    mapStyles() {
      return mapStyles();
    },
  },
};
</script>

<style lang="scss">
.select-store-map {
  width: 100%;
  position: relative;
  left: auto;
  right: 0;
  bottom: 0;
  top: 0;

  @include min-width(md) {
    position: absolute;
    width: 70vw;
  }
}

.select-store-map__map {
  height: 100vh;
  position: sticky;
  top: 0px;
}

.map {
  height: 100%;
  width: 100%;
}

.select-store-map__store-info {
  display: flex;
  flex-direction: column;
}

.button--map {
  padding-bottom: 0;
}

.store-mail-link {
  padding: 0 0.5rem 0.5rem 0.5rem;
}
</style>
