<template>
  <div id="app">
    <Header v-if="!IS_MAINTENANCE" />
    <main>
      <transition name="fade-router" mode="out-in">
        <router-view />
      </transition>

      <Alert v-if="hasError" type="error" position="fixed" isDismissable>
        <Margins>
          <p v-html="errorMessage" />
          <p class="text-extra-small" v-html="errorCode"></p>
        </Margins>
      </Alert>

      <Alert v-if="notification" type="success" position="fixed" isDismissable>
        <Margins>
          <p v-html="notification" />
        </Margins>
      </Alert>
    </main>
    <CookieBanner
      v-if="isCookieBannerOpen"
      @open-cookie-consent-modal="showCookieConsentModal"
    />
    <CookieModal
      :ref="cookieConsentModalRef"
      @toggle-cookie-banner="toggleCookieBanner"
      @open-cookie-info-modal="showCookieInfoModal"
    />
    <Modal v-model="isModalOpen" size="lg" @change="onChange">
      <template #header="{ close }">
        <Button variant="ghost" class="modal__close" @click="close">
          <div class="icon icon--small text-grey">
            <CloseSVG />
          </div>
        </Button>
      </template>
      <template #default>
        <CookieInfo />
      </template>
    </Modal>
    <Footer
      @open-cookie-consent-modal="showCookieConsentModal"
      @open-cookie-info-modal="showCookieInfoModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Footer, Header, CookieInfo } from '@/containers';
import { Alert, Margins, CookieBanner, CookieModal, Modal } from '@/components';
import { constants } from '@/mixins';
import { HANDLE_COOKIE_CONSENT, SET_COOKIE_CONSENT } from '@/types';
import CloseSVG from '@/assets/images/close.svg';

export default {
  name: 'App',
  mixins: [constants],
  data() {
    return {
      cookieBannerOpen: true,
      isModalOpen: false,
    };
  },
  components: {
    Alert,
    CookieModal,
    CookieBanner,
    CookieInfo,
    CloseSVG,
    Header,
    Modal,
    Margins,
    Footer,
  },
  async mounted() {
    this.handleConsent();
  },
  methods: {
    ...mapActions([SET_COOKIE_CONSENT, HANDLE_COOKIE_CONSENT]),
    showCookieConsentModal() {
      this.$refs[this.cookieConsentModalRef].showModal();
    },
    showCookieInfoModal() {
      this.isModalOpen = true;
    },
    toggleCookieBanner(open) {
      this.cookieBannerOpen = open;
    },
    handleConsent() {
      this.HANDLE_COOKIE_CONSENT();
    },
    onChange(isVisible) {
      this.isModalOpen = isVisible;
    },
  },
  computed: {
    ...mapState(['hasError', 'notification', 'consentInfo']),
    errorMessage() {
      const supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;

      return this.$t('DEFAULT_ERROR_MESSAGE', [
        '<br />',
        `<a href="mailto:${supportEmail}">${supportEmail}</a>`,
      ]);
    },
    errorCode() {
      return this.$t('DEFAULT_ERROR_CODE', [
        this.hasError.message || this.hasError.status,
      ]);
    },
    cookieConsentModalRef() {
      return `cookie-consent-modal`;
    },
    isCookieBannerOpen() {
      return this.cookieBannerOpen && !this.consentInfo?.levels?.length;
    },
  },
};
</script>

<style lang="scss">
.fade-router-enter-active,
.fade-router-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-router-enter,
.fade-router-leave-active {
  opacity: 0;
}
</style>
