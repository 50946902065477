// This file is quite pointless without custom VeeValidate.extend methods
// but it is here for the setup for future changes
import validationMessagesEN from 'vee-validate/dist/locale/en';
import validationMessagesDE from 'vee-validate/dist/locale/de';
import { Validator } from 'vee-validate';
import { isValidIban } from './utils';

Validator.extend('isAdult', {
  validate: (value) => {
    const now = new Date();
    const age = 18;
    const getBirthYear = new Date(value).getFullYear();
    const getNowYear = now.getFullYear();
    return getNowYear - getBirthYear > age;
  },
});
Validator.extend('isIban', {
  validate: (value) => {
    return isValidIban(value);
  },
});

export const adultyCustomValidator = new Validator({
  isAdult: 'isAdult',
});
export const ibanCustomValidator = new Validator({
  isIban: 'isIban',
});

export const dictionary = {
  en: {
    ...validationMessagesEN,
    messages: {
      ...validationMessagesEN.messages,
      isAdult: 'You must be at least 18 years old',
      isIban: 'Invalid IBAN',
      required: 'Please fill out this field.',
      regex: 'This field is not in a valid format.',
      email: 'This field is not in a valid format.',
    },
    custom: {
      emailConsent: {
        required: 'All terms must be accepted.',
      },
      schufaConsent: {
        required: 'All terms must be accepted.',
      },
      phonePublicityConsent: {
        required: 'All terms must be accepted.',
      },
      privacyConsent: {
        required: 'All terms must be accepted.',
      },
      iban: {
        regex: 'Invalid IBAN',
        max: 'Invalid IBAN',
      },
      currentpostalCode: {
        regex: 'This field must be 5 characters long.',
      },
      prevPostalCode: {
        regex: 'This field must be 5 characters long.',
      },
      passportNumber: {
        min: (field, [length]) =>
          `The field must be characters ${length} long.`,
        alpha_num:
          'This field is not in a valid format. Only letters and numbers are allowed.',
      },
      phoneNumberCode: {
        regex: 'This field must have at least 2 characters.',
      },
      phoneNumber: {
        regex: 'This field must have at least 3 characters.',
      },
      mobilePhoneNumberCode: {
        regex: 'This field must have at least 3 characters.',
      },
      employmentJobTitle: {
        regex:
          'This field is not in a valid format. Only letters and numbers are allowed.',
      },
      employerName: {
        regex:
          'This field is not in a valid format. Only letters and numbers are allowed.',
      },
      currentStreet: {
        regex:
          'This field is not in a valid format. Only letters and numbers are allowed.',
      },
      prevStreet: {
        regex:
          'This field is not in a valid format. Only letters and numbers are allowed.',
      },
      passportAuthority: {
        regex:
          'This field is not in a valid format. Only letters and numbers are allowed.',
      },
      currentHouseNo: {
        regex:
          'This field is not in a valid format. Only letters and numbers are allowed.',
      },
      prevHouseNo: {
        regex:
          'This field is not in a valid format. Only letters and numbers are allowed.',
      },
    },
  },
  de: {
    ...validationMessagesDE,
    messages: {
      ...validationMessagesDE.messages,
      isAdult: 'Sie müssen das 18. Lebensjahr vollendet haben',
      isIban: 'IBAN hat kein gültiges Format',
      required: 'Bitte füllen Sie dieses Feld aus.',
      regex: 'Dieses Feld hat kein gültiges Format.',
      numeric: 'Dieses Feld hat kein gültiges Format.',
      email: 'Dieses Feld hat kein gültiges Format.',
    },
    custom: {
      emailConsent: {
        required: 'Alle Bedingungen müssen akzeptiert werden.',
      },
      schufaConsent: {
        required: 'Alle Bedingungen müssen akzeptiert werden.',
      },
      phonePublicityConsent: {
        required: 'Alle Bedingungen müssen akzeptiert werden.',
      },
      privacyConsent: {
        required: 'Alle Bedingungen müssen akzeptiert werden.',
      },
      iban: {
        regex: 'IBAN hat kein gültiges Format',
        max: 'IBAN hat kein gültiges Format',
      },
      currentpostalCode: {
        regex: 'Dieses Feld muß 5-stellig sein.',
      },
      prevPostalCode: {
        regex: 'Dieses Feld muß 5-stellig sein.',
      },
      passportNumber: {
        min: (field, [length]) => `Dieses Feld muß ${length}-stellig sein.`,
        alpha_num:
          'Dieses Feld hat kein gültiges Format. Es sind nur Zahlen und Buchstaben erlaubt.',
        regex:
          'Dieses Feld hat kein gültiges Format. Es sind nur Zahlen und Buchstaben erlaubt.',
      },
      phoneNumberCode: {
        regex: 'Dieses Feld hat kein gültiges Format.',
      },
      phoneNumber: {
        regex: 'Dieses Feld hat kein gültiges Format.',
      },
      mobilePhoneNumberCode: {
        regex: 'Dieses Feld hat kein gültiges Format.',
      },
      employmentJobTitle: {
        regex:
          'Dieses Feld hat kein gültiges Format. Es sind nur Zahlen und Buchstaben erlaubt.',
      },
      employerName: {
        regex:
          'Dieses Feld hat kein gültiges Format. Es sind nur Zahlen und Buchstaben erlaubt.',
      },
      currentStreet: {
        regex:
          'Dieses Feld hat kein gültiges Format. Es sind nur Zahlen und Buchstaben erlaubt.',
      },
      prevStreet: {
        regex:
          'Dieses Feld hat kein gültiges Format. Es sind nur Zahlen und Buchstaben erlaubt.',
      },
      passportAuthority: {
        regex:
          'Dieses Feld hat kein gültiges Format. Es sind nur Zahlen und Buchstaben erlaubt.',
      },
      currentHouseNo: {
        regex:
          'Dieses Feld hat kein gültiges Format. Es sind nur Zahlen und Buchstaben erlaubt.',
      },
      prevHouseNo: {
        regex:
          'Dieses Feld hat kein gültiges Format. Es sind nur Zahlen und Buchstaben erlaubt.',
      },
    },
  },
};
