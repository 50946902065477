<template>
  <div id="wrapper" class="absolute">
    <h6>{{ $t('FORM.YOUR_PASSPORT_DATA') }}</h6>
    <b-row class="margins__one">
      <b-col md="5">
        <Select
          v-model.trim="form.maritalState"
          :label="`${$t('FORM.MARITAL_STATUS')} *`"
          name="maritalState"
          :options="MARITAL_STATUS"
          :error="errors.first('maritalState')"
          v-validate="{ required: true }"
          :data-vv-as="$t('FORM.VALIDATOR.MARITAL_STATUS')"
          class="margins__mobile-md-double"
        >
          <template #default="{ option }">{{ $t(option.label) }}</template>
        </Select>
      </b-col>
      <b-col md="7">
        <Select
          v-model="form.nationalityCountryCode"
          name="nationalityCountryCode"
          :options="countries"
          :error="errors.first('nationalityCountryCode')"
          v-validate="{ required: true }"
          :data-vv-as="$t('FORM.VALIDATOR.NATIONALITY')"
          class="margins__mobile-md-double"
        >
          <template #label>
            <Tooltip target="nationality">
              <template #parent>
                <span id="nationality" class="inline"
                  >{{ $t('FORM.NATIONALITY') }} *</span
                >
              </template>
              <template #default>
                <span> {{ $t('FORM.NATIONALITY_TOOLTIP') }} </span>
              </template>
            </Tooltip>
          </template>
          <template #default="slotProps">
            {{ $t(`COUNTRY.${slotProps.option.value}`) }}
          </template>
        </Select>
      </b-col>
    </b-row>
    <p class="text-grey text-small mt-4">
      {{ $t('FORM.DOCUMENT_IDENTIFICATION') }}
    </p>
    <b-row class="margins__one">
      <b-col md="5">
        <template v-if="isEU">
          <Select
            v-model="form.passportIdentification"
            :label="`${$t('FORM.DOCUMENT')} *`"
            name="passportIdentification"
            :options="DOCUMENT_OPTIONS"
            :error="errors.first('passportIdentification')"
            v-validate="{ required: true }"
            :data-vv-as="$t('FORM.VALIDATOR.DOCUMENT')"
            class="margins__mobile-md-double"
          >
            <template #default="{ option }">{{ $t(option.label) }}</template>
          </Select>
        </template>
        <template v-else>
          <InputField
            :value="$t('FORM.DOCUMENT_TYPE.PASSPORT')"
            :label="`${$t('FORM.DOCUMENT')} *`"
            name="passportIdentification"
            :error="errors.first('passportIdentification')"
            v-validate="{ required: true }"
            :data-vv-as="$t('FORM.VALIDATOR.DOCUMENT')"
            readonly
            class="margins__mobile-md-double"
          />
        </template>
      </b-col>

      <b-col md="3">
        <InputField
          v-model.trim="form.passportNumber"
          name="passportNumber"
          :error="errors.first('passportNumber')"
          :maxlength="10"
          v-validate="{
            required: true,
            alpha_num: true,
            min: isGerman ? 10 : 1,
          }"
          :data-vv-as="$t('FORM.VALIDATOR.DOCUMENT_NUMBER')"
          class="margins__mobile-md-double"
        >
          <template #label>
            <Tooltip
              target="passportNumber"
              container="body"
              fallback-placement="clockwise"
              placement="bottom"
            >
              <template #parent>
                <span id="passportNumber">
                  {{ $t('FORM.DOCUMENT_NUMBER') }} *
                </span>
              </template>
              <template #default>
                <PersonalDataTooltip />
              </template>
            </Tooltip>
          </template>
        </InputField>
      </b-col>
      <b-col md="4">
        <InputField
          v-model.trim="form.passportAuthority"
          :label="`${$t('FORM.DOCUMENT_ISSUER')} *`"
          name="passportAuthority"
          :error="errors.first('passportAuthority')"
          v-validate="{ required: true, regex: REGEX.DE.placeOfIssue }"
          :data-vv-as="$t('FORM.VALIDATOR.DOCUMENT_ISSUER')"
          class="margins__mobile-md-double"
        />
      </b-col>
    </b-row>
    <b-row class="margins__one">
      <b-col md="5">
        <Select
          v-if="!isEU"
          v-model.trim="form.residence.permitState"
          :label="`${$t('FORM.RESIDENTIAL_STATUS')}`"
          name="permitState"
          v-validate="{ required: true }"
          :error="errors.first('permitState')"
          :data-vv-as="$t('FORM.VALIDATOR.RESIDENTIAL_STATUS')"
          :options="residentialStatuses"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="3">
        <CustomDatePicker
          v-if="!isEU && isLimited"
          v-model.trim="form.residence.permitUntil"
          :placeholder="`${$t('FORM.PLACEHOLDER.CALENDAR')}`"
          name="residentialStatus"
          :error="errors.first('residentialStatus')"
          v-validate="{ required: true }"
          :data-vv-as="$t('FORM.VALIDATOR.RESIDENTIAL_STATUS')"
          :locale="$i18n.locale"
          :masks="masks"
          class="margins__mobile-md-double"
        >
          <template #label>
            <Tooltip target="permitUntil">
              <template #parent>
                <span id="permitUntil" class="inline">
                  {{ $t('FORM.VALID_TO') }} *
                </span>
              </template>
              <template #default>
                <span> {{ $t('FORM.RESIDENTIAL_STATUS_TOOLTIP') }} </span>
              </template>
            </Tooltip>
          </template>
        </CustomDatePicker>
      </b-col>
    </b-row>
    <h6 class="mt-5">{{ $t('FORM.YOUR_CANTACT_DATA') }}</h6>
    <b-row class="margins__one">
      <b-col md="3">
        <InputField
          :label="`${$t('FORM.TELEPHONE')} *`"
          name="phoneNumberCode"
          :maxlength="6"
          v-model.trim="form.phoneNumberCode"
          :error="errors.first('phoneNumberCode')"
          prefix="🇩🇪"
          v-validate="{
            required: true,
            numeric: true,
            regex: REGEX.DE.phoneNumberCode,
          }"
          :data-vv-as="$t('FORM.VALIDATOR.PHONE_CODE')"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="3" class="mt-3 pt-1">
        <InputField
          v-model.trim="form.phoneNumber"
          name="phoneNumber"
          :maxlength="9"
          :error="errors.first('phoneNumber')"
          v-validate="{
            required: true,
            numeric: true,
            regex: REGEX.DE.phoneNumberExtension,
          }"
          :data-vv-as="$t('FORM.VALIDATOR.PHONE_EXTENSION')"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="6">
        <InputField
          v-model.trim="form.email"
          :label="`${$t('FORM.EMAIL')} *`"
          name="email"
          type="email"
          :maxlength="100"
          autocomplete="email"
          :error="errors.first('email')"
          v-validate="{ required: true, email: true }"
          :data-vv-as="$t('FORM.VALIDATOR.EMAIL')"
          class="margins__mobile-md-double"
        />
      </b-col>
    </b-row>

    <!--EMAIL CONSENT-->

    <div class="margins__one">
      <Check
        v-model="form.emailConsent"
        name="emailConsent"
        v-validate="'required: true'"
        :data-vv-as="$t('FORM.VALIDATOR.EMAIL_CONSENT')"
        :error="errors.first('emailConsent')"
      >
        <template #label>
          <i18n path="FORM.EMAIL_CONSENT" tag="p">
            <template #consentButton>
              <Button variant="link" size="small" @click.stop="showEmailModal">
                <span class="hint">{{ $t('FORM.HINT') }}</span>
              </Button>
            </template>
          </i18n>
        </template>
      </Check>
    </div>
    <Modal v-model="isEmailConsentVisible" @change="hideEmailModal($event)">
      <template #header="{ close }">
        <div class="container">
          <b-row>
            <b-col cols="11">
              <h6>{{ $t('FORM.HINT') }}</h6>
            </b-col>
            <b-col col>
              <Button variant="ghost" @click="close" size="small">
                <CloseSVG class="icon" />
              </Button>
            </b-col>
          </b-row>
        </div>
      </template>
      <template #default>
        <div class="container">
          <div class="margins-double">
            <i18n path="FORM.EMAIL_CONSENT_MODAL" tag="div">
              <template #firstRow>
                <p class="mb-4">
                  {{ $t('FORM.CONSENT_MODAL_FIRST') }}
                </p>
              </template>
              <template #secondRow>
                <p class="mb-4">
                  {{ $t('FORM.CONSENT_MODAL_SECOND') }}
                </p>
              </template>
              <template #targoLink>
                <a
                  href="http://www.targobank.de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.targobank.de
                </a>
              </template>
            </i18n>
          </div>
        </div>
      </template>
    </Modal>
    <slot :validate="validate" :form="form" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { constants, createOptions } from '@/mixins';
import {
  Button,
  Check,
  Input as InputField,
  Select,
  CustomDatePicker,
  Modal,
  Tooltip,
} from '@/components';
import CloseSVG from '@/assets/images/times-circle-regular.svg';

import { MARITAL_STATUS, DOCUMENT_OPTIONS } from '@/constants';
import { residentialStatuses, unlimitedPermits } from '@/constants';
import PersonalDataTooltip from '../PersonalDataTooltip.vue';

export default {
  name: 'ApplicantPassportData',
  mixins: [constants, createOptions],
  components: {
    InputField,
    Button,
    Check,
    Select,
    CustomDatePicker,
    Modal,
    CloseSVG,
    Tooltip,
    PersonalDataTooltip,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {},
      residentialStatuses,
      unlimitedPermits,
      MARITAL_STATUS,
      DOCUMENT_OPTIONS,
      isEmailConsentVisible: false,
    };
  },
  created() {
    this.form = {
      ...this.formData,
      ...this.form,
      residence: {
        permitState: this.formData.residence?.permitState || null,
        permitUntil: this.formData.residence?.permitUntil || null,
      },

      emailConsent: null,
    };
  },

  mounted() {
    this.scrollToTop();
  },

  watch: {
    'form.nationalityCountryCode': {
      handler(val) {
        const country = this.countryList.find(
          (country) => country.code === val,
        );

        if (!country.isEU) {
          this.form.passportIdentification = 'PASSPORT';
        } else {
          this.form.residence = {
            permitState: null,
            permitUntil: null,
          };
        }
      },
    },
  },

  computed: {
    ...mapState(['countryList']),

    countries() {
      const de = this.countryList.find(({ code }) => code === 'DE');
      const filteredCountries = this.countryList
        .filter(({ code }) => code !== 'DE')
        .map((country) => ({
          value: country.code,
          isEU: country.isEU,
          label: this.$t(`COUNTRY.${country.code}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      if (de) {
        filteredCountries.unshift({
          value: de.code,
          isEU: de.isEU,
          label: this.$t(`COUNTRY.${de.code}`),
        });
      }
      return filteredCountries;
    },
    isEU() {
      const country = this.countryList.find(
        (country) => country.code === this.form.nationalityCountryCode,
      );
      return country ? country.isEU : true;
    },
    isLimited() {
      return (
        this.form.residence.permitState &&
        !this.unlimitedPermits.includes(this.form.residence.permitState)
      );
    },
    masks() {
      return { input: 'DD/MM/YYYY' };
    },
    isGerman() {
      return this.form.nationalityCountryCode === 'DE';
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    showEmailModal() {
      this.isEmailConsentVisible = true;
    },
    hideEmailModal(event) {
      this.isEmailConsentVisible = event;
    },
    async validate() {
      const valid = await this.$validator.validateAll();
      return valid;
    },
  },
};
</script>
