<template>
  <ul
    :class="[
      'list',
      { [`list--${variant}`]: !!variant },
      { [`list--${size}`]: !!size },
      { [`list--icon-${icon}`]: !!icon },
    ]"
    v-if="haveItems"
  >
    <li v-for="(item, index) in items" :key="index" class="list__item">
      <slot name="default" :list-item="item">
        <span v-html="item"></span>
      </slot>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'List',
  props: {
    items: [Array, Object],
    variant: {
      validator: function (value) {
        return ['numbers', 'bullets', 'icons'].includes(value);
      },
    },
    size: {
      validator: function (value) {
        return ['small'].includes(value);
      },
    },
    icon: {
      validator: function (value) {
        return ['check', 'plus'].includes(value);
      },
      default: 'check',
    },
  },
  computed: {
    haveItems() {
      return this.items.length > 0 || Object.keys(this.items).length > 0;
    },
  },
};
</script>

<style lang="scss">
.list {
  list-style-type: none;
}

.list--numbers {
  counter-reset: number;
}

.list__item {
  position: relative;

  .list--numbers & {
    padding-left: rem(24px);
  }

  .list--small.list--numbers & {
    padding-left: 1rem;
  }

  .list--bullets & {
    padding-left: rem(12px);
  }

  .list--icons & {
    padding-left: rem(48px);
  }

  .list--small.list--icons & {
    padding-left: rem(28px);
  }

  + .list__item {
    margin-top: 0.5rem;

    .list--icons & {
      margin-top: 2rem;
    }

    .list--numbers & {
      margin-top: 1rem;
    }
  }

  &::before {
    content: '\200B'; /* Add zero-width space */

    .list--numbers & {
      counter-increment: number;
      content: '' counter(number) '.';
      position: absolute;
      left: 0;
      top: rem(-4px);
      font-family: $font-family-header;
      font-size: 1.25em;
      color: $color-primary;
    }

    .list--small.list--numbers & {
      font-family: $font-family;
      font-size: 1em;
      color: inherit;
    }

    .list--bullets & {
      content: '•';
      position: absolute;
      left: 0;
      top: 0;
    }

    .list--icons.list--icon-check & {
      position: absolute;
      left: 0;
      top: rem(-3px);
      background: $color-primary no-repeat center center;
      background-size: 60%;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M4.02549 11.5597C3.37581 10.6664 2.12498 10.4689 1.23168 11.1186C0.338371 11.7682 0.140872 13.0191 0.790549 13.9124L4.02549 11.5597ZM10.6466 24.064L9.02909 25.2404C9.41218 25.7671 10.0278 26.0743 10.679 26.0637C11.3303 26.0532 11.9356 25.7262 12.3014 25.1873L10.6466 24.064ZM26.7188 3.94725C27.3392 3.03334 27.1012 1.78957 26.1873 1.16922C25.2734 0.548867 24.0296 0.786844 23.4092 1.70076L26.7188 3.94725ZM0.790549 13.9124L9.02909 25.2404L12.264 22.8877L4.02549 11.5597L0.790549 13.9124ZM12.3014 25.1873L26.7188 3.94725L23.4092 1.70076L8.99178 22.9408L12.3014 25.1873Z'/%3E%3C/svg%3E");
      height: rem(26px);
      width: rem(26px);
      border-radius: 99em;
    }

    .list--small.list--icons.list--icon-check & {
      top: rem(-1px);
      height: rem(22px);
      width: rem(22px);
    }

    .list--icons.list--icon-plus & {
      position: absolute;
      top: 0;
      left: 0;
      top: rem(-3px);
      background: transparent no-repeat center center;
      background-image: $list-icon-plus;
      height: rem(26px);
      width: rem(26px);
    }

    .list--small.list--icons.list--icon-plus & {
      top: rem(-1px);
      height: rem(20px);
      width: rem(20px);
    }
  }
}
</style>
