export const API_BASE_URL = process.env.VUE_APP_API_URL;
export const IMAGE_BASE_URL = process.env.VUE_APP_IMAGE_BASE_URL;
export const SUPPORT_EMAIL = process.env.VUE_APP_SUPPORT_EMAIL;
export const SELLER_CODE = process.env.VUE_APP_SELLER_CODE;
export const MAIN_LOCALE = window.config.vue.vueAppI18nLocale;
export const AVAILABLE_LOCALES = (
  window.config.vue.vueAppI18nAvailableLocales || MAIN_LOCALE
).split(',');
export const ENVIRONMENT = window.config.vue.vueAppEnvironment;

export const SIGNICAT_LOGIN_URL = '/login/signicat';

export const ROUTES = {
  HOME: {
    path: '/',
    name: 'home',
  },
  MAINTENANCE: {
    path: '/maintenance',
    name: 'maintenance',
  },
  SELECT_BUNDLE: {
    path: '/select-bundle',
    name: 'select-bundle',
  },
  APPLY: {
    path: '/apply',
    name: 'apply',
  },
  SELECT_STORE: {
    path: '/select-store',
    name: 'select-store',
  },
  FAQ: {
    path: '/faq',
    name: 'faq',
  },
  LOAN_APPLICATION: {
    path: '/apply/loan-application',
    name: 'loan-application',
  },
  LOAN_APPLICATION_LOADING: {
    path: '/apply/loan-application/loading',
    name: 'loan-application-loading',
  },
  LOAN_APPLICATION_SUCCESS: {
    path: '/apply/loan-application/success',
    name: 'loan-application-success',
  },
  LOAN_APPLICATION_FAIL: {
    path: '/apply/loan-application/fail/*',
    name: 'loan-application-fail',
  },
  LOAN_APPLICATION_REJECT: {
    path: '/apply/loan-application/reject/*',
    name: 'loan-application-reject',
  },
  DECISION_MANUAL_INSPECTION: {
    path: '/decision/manual-inspection',
    name: 'decision-manual-inspection',
  },
  LOAN_PAYMENT_RESERVATION_LOADING: {
    path: '/apply/loan-application/reservation-payment',
    name: 'loan-payment-reservation-loading',
  },
  DECISION_DENIED: {
    path: '/decision/denied',
    name: 'decision-denied',
  },
  DECISION_SIGN: {
    path: '/decision/sign',
    name: 'decision-sign',
  },
  DECISION_SIGN_MANUALLY: {
    path: '/decision/sign/manually',
    name: 'decision-sign-manually',
  },
  BANK_ID_FAIL: {
    path: '/bank-id/fail',
    name: 'bank-id-fail',
  },
  LOGIN_SUCCESS: {
    path: '/login-success',
    name: 'login-success',
  },
  LOGIN_FAIL: {
    path: '/login-fail',
    name: 'login-fail',
  },
  CREDIT_CARD_PAYMENT: {
    path: '/credit-card/payment',
    name: 'credit-card-payment',
  },
  CREDIT_CARD_PAYMENT_FAIL: {
    path: '/credit-card/payment-fail',
    name: 'credit-card-payment-fail',
  },
};

export const SERVICE_TYPES = [
  {
    type: 'INSTALLATION',
    icon: 'StihlInstallationSVG',
  },
  {
    type: 'SERVICE',
    icon: 'StihlServiceSVG',
  },
  {
    type: 'INSURANCE',
    icon: 'StihlWinterStorageSVG',
  },
  {
    type: 'GUARANTEE',
    icon: 'StihlGuaranteeSVG',
  },
  { type: 'PERFECT', icon: 'StihlPerfectPlanSVG' },
];

export const PERSISTED_STORE_KEY = 'fairown-persisted-store';

export const STEPS = {
  select: 0,
  apply: 1,
  paymentSubmitted: 2,
};

export const REGEX = {
  DE: {
    name: "^[a-zA-ZüÜäÄöÖßÀÁÂÃÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕØÙÚÛÝÞàáâãåæçèéêëìíîïðñòóôõøùúûýþ| |-|-.'s]+$",
    postalCode: '^[0-9]{5}$',
    mobileNumberCode: '^[0-9]{3,6}$',
    phoneNumberCode: '^0[0-9]{1,5}$',
    phoneNumberExtension: '^[1-9][0-9]{2,8}$',
    mobileNumberExtension: '^[1-9]+$',
    houseNumber: '^[a-zA-Z-s0-9 ]+$',
    placeOfIssue: '^[a-zA-ZüÜäÄöÖßs0-9. -]{3,}$',
    iban: '^DE|de[0-9]{2}[0-9]{8}[0-9]{10}$',
    streetAddress: '^[a-zA-ZüÜäÄöÖßs0-9 .-]+$',
    jobTitle: '^[a-zA-ZüÜäÄöÖßs0-9. -]{3,}$',
    employerName: '^[a-zA-ZüÜäÄöÖßs0-9. -]{3,}$',
    employerLocation: '^[a-zA-Z-.üÜäÄößs ]+$',
    city: '^[a-zA-Z-.üÜäÄößs ]+$',
    birthPlace: '^[a-zA-Z-.üÜäÄößs ]+$',
  },
};

export const DECISION_TYPES = {
  SUPPLEMENTING_REQUIRED: 'SUPPLEMENTING_REQUIRED',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
};

export const INDIVIDUALIZED_TERMS_TYPES = ['COMMON', 'ISEKKI_NEW_ACCOUNT'];

export const DELIVERY_TYPES = {
  // HOME: 'HOME',
  STORE: 'STORE',
};

export const SIGNATURE_STATUSES = {
  CREATED: 'CREATED',
  NOTIFIED: 'NOTIFIED',
  STARTED: 'STARTED',
  COMPLETE: 'COMPLETE',
  CANCELLED: 'CANCELLED',
  UNKNOWN: 'UNKNOWN',
};

export const COOKIE_CONSENT_LEVELS = {
  NECESSARY: 'NECESSARY',
  ANALYTIC: 'ANALYTIC',
  TARGETING: 'TARGETING',
};

export const SIGNING_TYPES = { MANUAL: 'MANUAL', BANKID: 'BANKID' };

export const ORDER_STATUSES = {
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  HANDED_OVER: 'HANDED_OVER',
  DENIED: 'DENIED',
};

export const LOCALES = {
  EN: 'en',
  DE: 'de',
};

export const STORE = {
  name: 'Stihl',
  lang: 'de',
  availableLocales: AVAILABLE_LOCALES,
  mapConfig: {
    centerPosition: { lat: 51.1657, lng: 10.4515 },
    zoom: 6.3,
  },
};

export const CUSTOMER_TYPES = {
  NATURAL: 'NATURAL',
};

const socialMediaItems = [
  {
    icon: 'BIconFacebook',
    url: 'https://www.facebook.com/stihl.de/',
    label: 'FACEBOOK',
  },
  {
    icon: 'BIconInstagram',
    url: 'https://www.instagram.com/stihl.de',
    label: 'INSTAGRAM',
  },
  {
    icon: 'BIconYoutube',
    url: 'https://www.youtube.com/watch?v=Y6lQDKPhMww',
    label: 'YOUTUBE',
  },
];

const contactItems = [
  {
    label: 'ADDRESS',
    value:
      'STIHL Vertriebszentrale AG & Co. KG Robert-Bosch-Straße 13, D-64807 Dieburg',
  },
  { label: 'EMAIL', value: 'info@stihl.de' },
];

const companyItems = [
  { label: 'ABOUT_US', url: 'https://corporate.stihl.de/de/ueber-stihl ' },
];

export const footerDataMap = new Map([
  ['SocialMediaItems', socialMediaItems],
  ['ContactItems', contactItems],
  ['CompanyItem', companyItems],
]);

export const residentialStatuses = [
  { label: 'Daueraufenthalt EU', value: 'UNLIMITED_RESIDENCE_EU' },
  { label: 'FreiZügG Aufenthaltskarte', value: 'RESIDENCE_CARD_EU' },
  {
    label: 'FreiZügG Daueraufenthaltskarte',
    value: 'UNLIMITED_CARD_RESIDENCE4A_EU',
  },
  { label: 'Niederlassungserlaubnis', value: 'GRANTED_SETTLEMENT_PERMIT' },
  {
    label: 'nach anderen §§ Aufenthaltserlaubnis',
    value: 'RESIDENCE_PERMIT_MISCELLANEOUS',
  },
  {
    label: '§ 18 a,b,c  befristete Aufenthaltserlaubnis',
    value: 'LIMITED_RESIDENT_PERMIT_DEPENDS_ON_18ABC',
  },
  {
    label: '§ 21 befristete Aufenthaltserlaubnis',
    value: 'LIMITED_RESIDENT_PERMIT_DEPENDS_ON_21',
  },
  {
    label: '§ 28 befristete Aufenthaltserlaubnis',
    value: 'LIMITED_RESIDENT_PERMIT_DEPENDS_ON_28',
  },
  {
    label: '§ 30 befristete Aufenthaltserlaubnis',
    value: 'LIMITED_RESIDENT_PERMIT_DEPENDS_ON_30',
  },
  {
    label: 'Aufenthaltstitel § 18 Abs.3/4 vor 03.2020',
    value: 'LIMITED_RESIDENT_PERMIT_DEPENDS_ON_18BEF032020',
  },
  {
    label: 'Aufenthaltstitel § 19a vor 03.2020',
    value: 'LIMITED_RESIDENT_PERMIT_DEPENDS_ON_19ABEF032020',
  },
];

export const unlimitedPermits = [
  'UNLIMITED_RESIDENCE_EU',
  'UNLIMITED_CARD_RESIDENCE4A_EU',
  'GRANTED_SETTLEMENT_PERMIT',
];
export const bundleHeroImageMap = {
  IMOW_RMI: 'sai_rmi_hero.jpg',
  IMOW: 'sai_imow_hero.jpg',
  IMOW_EVO: 'sai_imow-evo_hero.jpg',
  BASE: 'hero.jpg',
};

export const GENDER_OPTIONS = [
  {
    label: 'FORM.MALE',
    value: 'MALE',
  },
  {
    label: 'FORM.FEMALE',
    value: 'FEMALE',
  },
];

export const DOCUMENT_OPTIONS = [
  {
    label: 'FORM.DOCUMENT_TYPE.PASSPORT',
    value: 'PASSPORT',
  },
  {
    label: 'FORM.DOCUMENT_TYPE.ID_CARD',
    value: 'IDENTIFICATION_CARD',
  },
];

export const MARITAL_STATUS = [
  {
    label: 'FORM.MARITAL_STATUS_OPTIONS.SINGLE',
    value: 'SINGLE',
  },
  {
    label: 'FORM.MARITAL_STATUS_OPTIONS.MARRIED',
    value: 'MARRIED',
  },
  {
    label: 'FORM.MARITAL_STATUS_OPTIONS.SEPARATED',
    value: 'SEPARATED',
  },
  {
    label: 'FORM.MARITAL_STATUS_OPTIONS.DIVORCED',
    value: 'DIVORCED',
  },
  {
    label: 'FORM.MARITAL_STATUS_OPTIONS.WIDOWED',
    value: 'WIDOWED',
  },
  {
    label: 'FORM.MARITAL_STATUS_OPTIONS.COHABITATION',
    value: 'COHABITATION',
  },
];

export const OCCUPATION_TYPE = [
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.EMPLOYEE',
    value: 'EMPLOYEE',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.PUBLIC_SERVANT',
    value: 'PUBLIC_SERVANT',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.CIVIL_SERVANT',
    value: 'CIVIL_SERVANT',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.LABOURER',
    value: 'LABOURER',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.HOMEMAKER',
    value: 'HOMEMAKER',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.PENSIONER',
    value: 'PENSIONER',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.APPRENTICE',
    value: 'APPRENTICE',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.STUDENT',
    value: 'STUDENT',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.SALES_REPRESENTATIVE',
    value: 'SALES_REPRESENTATIVE',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.SALES_PEOPLE',
    value: 'SALES_PEOPLE',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.MISCELLANEOUS',
    value: 'MISCELLANEOUS',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.UNEMPLOYEED',
    value: 'NO_WORK',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.SALARIED_MANAGING_DIRECTOR',
    value: 'SALARIED_MANAGING_DIRECTOR',
  },
  {
    label: 'FORM.OCCUPATION_GROUP_OPTIONS.SOLDIER',
    value: 'SOLDIER',
  },
];

export const OCCUPATION_INDUSTRY = [
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.BANK',
    value: 'BANKS',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.BUILDING_COMPANY',
    value: 'BUILDING_COMPANY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.MINING',
    value: 'MINING',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.ARMY',
    value: 'ARMY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.CHEMICAL_INDUSTRY',
    value: 'CHEMICAL_INDUSTRY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.DIPLOMATIC_SERVICE',
    value: 'FOREIGN_SERVICE',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.PAPER_SERVICE',
    value: 'PAPER_INDUSTRY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.FISHING',
    value: 'FISHING',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.GASTRONOMY',
    value: 'GASTRONOMY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.RETAIL',
    value: 'WHOLESALE_RETAIL',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.HANDCRAFT',
    value: 'HANDCRAFT',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.FOREST_INDUSTRY',
    value: 'TIMBER_INDUSTRY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.HOSPITALITY',
    value: 'HOTEL_BUSINESS',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.STEELWORKS',
    value: 'STEEL_INDUSTRY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.CAR_MANUFACTURER',
    value: 'VEHICLE_MANUFACTURER',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.KINDERGARTEN',
    value: 'KINDERGARTEN',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.CHURCH_AND_SOCIAL',
    value: 'WELFARE_SERVICE',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.WELFARE_SERVICE',
    value: 'CARE_INSTITUTION',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.AGRICULTURE',
    value: 'AGRICULTURE_FORESTRY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.METAL_INDUSTRY',
    value: 'METAL_INDUSTRY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.FOOD_INDUSTRY',
    value: 'FOOD_INDUSTRY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.PUBLIC_SERVICE',
    value: 'PUBLIC_SERVICE',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.TRAVEL_INDUSTRY',
    value: 'TRAVEL_INDUSTRY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.BROADCASTING',
    value: 'BROADCASTING_INSTITUTION',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.SEAMAN',
    value: 'SEAFARING',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.MISCELLANEOUS',
    value: 'MISCELLANEOUS',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.FREIGHT',
    value: 'FREIGHT_FORWARDING',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.GAS_STATIONS',
    value: 'PETROL_STATIONS',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.TEXTILE_INDUSTRY',
    value: 'TEXTILE_INDUSTRY',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.PRESS',
    value: 'PRESS',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.INSURANCE',
    value: 'INSURANCE',
  },
  {
    label: 'FORM.OCCUPATION_INDUSTRY_TYPE.ROLLING_MILL',
    value: 'ROLLER_MILL',
  },
];

export const HOUSING_TYPE = [
  {
    label: 'FORM.HOUSE_TYPE_OPTION.PRIVATE_PROPERTY',
    value: 'PROPRIETARY',
  },
  {
    label: 'FORM.HOUSE_TYPE_OPTION.RENTAL',
    value: 'RENT',
  },
  {
    label: 'FORM.HOUSE_TYPE_OPTION.WITH_PARENTS',
    value: 'PARENTS',
  },
];

export const LOAN_APPLICATION_STEPS = {
  1: 'ApplicantPersonalData',
  2: 'ApplicantPassportData',
  3: 'ApplicantProfessionData',
  4: 'ApplicantBudgetData',
  5: 'ApplicantIBANData',
  6: 'ApplicantPersonalPhoneData',
};

export const ONLINE_APPLICATION_PROMOS = [
  {
    header: 'FORM.APPLICATION_PROMO.DIGITAL.HEADER',
    text: 'FORM.APPLICATION_PROMO.DIGITAL.TEXT',
  },
  {
    header: 'FORM.APPLICATION_PROMO.SECURE.HEADER',
    text: 'FORM.APPLICATION_PROMO.SECURE.TEXT',
  },
  {
    header: 'FORM.APPLICATION_PROMO.SIMPLE.HEADER',
    text: 'FORM.APPLICATION_PROMO.SIMPLE.TEXT',
  },
];
export const LIST_ITEMS = [
  {
    title: 'ORDER_COMPLETE_CHECKLIST.ITEM_1',
    text: 'ORDER_COMPLETE_CHECKLIST.ITEM_1_TEXT',
    extraInfo: 'ORDER_COMPLETE_CHECKLIST.ITEM_1_HINT',
  },
  {
    title: 'ORDER_COMPLETE_CHECKLIST.ITEM_2',
    text: 'ORDER_COMPLETE_CHECKLIST.ITEM_2_TEXT',
    extraInfo: 'ORDER_COMPLETE_CHECKLIST.ITEM_2_HINT',
  },
  {
    title: 'ORDER_COMPLETE_CHECKLIST.ITEM_3',
    text: 'ORDER_COMPLETE_CHECKLIST.ITEM_3_TEXT',
    extraInfo: 'ORDER_COMPLETE_CHECKLIST.ITEM_3_HINT',
  },
  {
    title: 'ORDER_COMPLETE_CHECKLIST.ITEM_4',
    text: 'ORDER_COMPLETE_CHECKLIST.ITEM_4_TEXT',
    extraInfo: 'ORDER_COMPLETE_CHECKLIST.ITEM_4_HINT',
    hasPaidInfo: true,
  },
];

export const POLICY_LINKS = [
  {
    name: 'imprint',
    href: 'https://www.stihl.de/de/impressum',
    text: 'COOKIE_CONSENT.LINKS.IMPRINT',
  },
  {
    name: 'privacyPolicy',
    href: `${window.location.origin}/documents/Datenschutzerklärung.pdf`,
    text: 'COOKIE_CONSENT.LINKS.PRIVACY_POLICY',
  },
  {
    name: 'cookiePolicy',
    text: 'COOKIE_CONSENT.LINKS.COOKIE_POLICY',
  },
];
