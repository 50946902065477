<template>
  <div class="marketing-view">
    <Container>
      <Separator size="medium" />
      <Margins>
        <h3>
          {{ $t('LANDING.MONTHLY_PRICE') }}
        </h3>
        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              {{ $t('LANDING.IMOW_FIRST') }}
            </Margins>
          </b-col>
        </b-row>

        <Separator hasLine size="medium" />

        <h3>{{ $t('LANDING.ALL_INCLUSIVE') }}</h3>

        <b-list-group>
          <b-list-group-item
            v-for="item in allInclusiveExplanationList"
            :key="item.name"
            class="d-flex flex-row align-items-center mt-3"
          >
            <component
              :is="item.icon"
              class="icon icon--medium fill-white mr-2"
            />
            <span>{{ $t(item.text) }}</span>
          </b-list-group-item>
        </b-list-group>

        <div class="margins__triple">
          <p>
            <small class="text-extra-small">
              {{ $t('LANDING.INSTALLATION_INFO') }}
            </small>
          </p>
        </div>

        <div class="text-left">
          <Button
            @click="$router.push({ name: ROUTES.SELECT_BUNDLE.name })"
            variant="inverse-primary"
          >
            {{ $t('HEADER.HOME') }}
          </Button>
        </div>

        <Separator size="medium" hasLine />

        <h3>
          {{ $t('LANDING.BEST_LAWN') }}
        </h3>

        <List :items="listItems" variant="numbers" />

        <p class="margins__triple">
          <small class="text-extra-small">
            {{ $t('LANDING.DISTANCE_INFO') }}
          </small>
        </p>

        <Button variant="inverse-primary" @click="scrollTo('#map')">
          {{ $t('LANDING.FIND_RETAILERS') }}
        </Button>

        <Separator size="medium" hasLine />

        <MarketingFAQ />

        <Separator size="medium" hasLine />

        <h3>
          <i18n path="LANDING.MORE_INFO">
            <template #link>
              <a
                href="https://www.stihl.de/de/imow"
                target="_blank"
                rel="noopener noreferrer"
              >
                STIHL.DE/iMOW
              </a>
            </template>
          </i18n>
        </h3>

        <b-embed
          v-if="hasTargetingConsentLevel"
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/ZVI2XbYf-u0"
          allowfullscreen
        ></b-embed>
      </Margins>
    </Container>

    <Separator size="medium" />

    <div id="map">
      <SelectStore view />
    </div>
  </div>
</template>

<script>
import { Container, Separator, Button, Margins, List } from '@/components';
import StihlInstallationSVG from '@/assets/images/stihl-installation.svg';
import StihlServiceSVG from '@/assets/images/stihl-service.svg';
import StihlWinterStorageSVG from '@/assets/images/stihl-winter-storage.svg';
import StihlPerfectPlanSVG from '@/assets/images/stihl-perfect-plan.svg';
import StihlGuaranteeSVG from '@/assets/images/product-registration-icon.svg';
import SelectStore from '@/views/SelectStore/SelectStore';
import MarketingFAQ from '@/containers/MarketingFAQ/MarketingFAQ.vue';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { mapGetters } from 'vuex';
import { COOKIE_CONSENT_LEVELS } from '@/constants';

export default {
  name: 'MarketingView',
  mixins: [constants],
  components: {
    Container,
    Separator,
    Button,
    Margins,
    List,
    StihlInstallationSVG,
    StihlServiceSVG,
    StihlWinterStorageSVG,
    StihlPerfectPlanSVG,
    StihlGuaranteeSVG,
    SelectStore,
    MarketingFAQ,
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1);
    }
  },
  methods: {
    scrollTo(hashtag) {
      setTimeout(() => {
        window.location.href = hashtag;
      }, 1);
    },
  },
  computed: {
    ...mapGetters(['hasConsentByLevel']),
    listItems() {
      return [
        this.$t('LANDING.NUMBERED_LIST.FIRST'),
        this.$t('LANDING.NUMBERED_LIST.SECOND'),
        this.$t('LANDING.NUMBERED_LIST.THIRD'),
        this.$t('LANDING.NUMBERED_LIST.FOURTH'),
      ];
    },
    allInclusiveExplanationList() {
      return [
        {
          name: 'installation',
          text: this.$t('LANDING.INSTALLATION'),
          icon: 'StihlInstallationSVG',
        },
        {
          name: 'storage',
          text: this.$t('LANDING.WINTER_STORAGE'),
          icon: 'StihlWinterStorageSVG',
        },
        {
          name: 'guarantee',
          text: this.$t('LANDING.GUARANTEE'),
          icon: 'StihlGuaranteeSVG',
        },
        {
          name: 'lawn',
          text: this.$t('LANDING.PERFECT_LAWN'),
          icon: 'StihlServiceSVG',
        },
      ];
    },
    returnChecklist() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_CHECKLIST_ITEMS'),
      ).map((r) => r);
    },
    returnItems() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_ITEMS_ITEMS'),
      ).map((r) => r);
    },
    hasTargetingConsentLevel() {
      return this.hasConsentByLevel(COOKIE_CONSENT_LEVELS.TARGETING);
    },
  },
};
</script>
