import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import {
  ASK_QUESTION,
  GET_APPLICATION_STATUS,
  GET_BUNDLES,
  GET_IS_ADMIN,
  GET_LOGIN_INFO,
  GET_STORES,
  HAS_ERROR,
  HAS_LOAN_APPLICATION_ERROR,
  IS_BUNDLE_ORDER_COMPLETE,
  IS_LOADING,
  NOTIFICATION,
  RESET_STORE,
  SET_APPLICATION_STATUS,
  SET_BUNDLE_SELECTED_STORE_CODE,
  SET_BUNDLES,
  SET_CURRENT_STEP,
  SET_INDIVIDUALIZED_TERMS,
  SET_IS_ADMIN,
  SET_LANGUAGE,
  SET_LOAN_APPLICATION_DATA,
  SET_LOAN_FORM_DATA,
  SET_LOAN_APPLICATION_DECISION,
  SET_LOGIN_INFO,
  SET_SELECTED_BUNDLE,
  SET_SIGNATURE_STATUS,
  SET_SIGNING_DATA,
  SET_STORES,
  SUBMIT_LOAN_APPLICATION,
  SET_COOKIE_CONSENT,
  HANDLE_COOKIE_CONSENT,
  SET_LOAN_REFERENCE_NUMBER,
  SET_GEOCODER_ERROR,
  SET_REJECTION_REASON,
  SET_DECISION,
  GET_COUNTRY_LIST,
  SET_COUNTRY_LIST,
  SET_APPLICATION_STEP,
  SET_PAYMENT,
  SET_AGREEMENT_TEXT,
} from '@/types';
import {
  COOKIE_CONSENT_LEVELS,
  DECISION_TYPES,
  PERSISTED_STORE_KEY,
  ROUTES,
  STEPS,
} from './constants';
import router from './router';
import i18n from './i18n';
import { getPersistedStore } from './utils';
import {
  cookieRevision,
  getConsentObjectFromCookie,
  setCookie,
} from '@/utils/CookieUtil';
import SubscriptionApi from '@/api/SubscriptionApi';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: PERSISTED_STORE_KEY,
  storage: window.localStorage,
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (state) => ({
    language: state.language,
    currentStep: state.currentStep,
    selectedBundle: state.selectedBundle,
    loginInfo: state.loginInfo,
    loanApplicationData: state.loanApplicationData,
    loanApplicationDecision: state.loanApplicationDecision,
    loanReferenceNumber: state.loanReferenceNumber,
    signingData: state.signingData,
    storeCode: state.storeCode,
    countryList: state.countryList,
    step: state.step,
    resolution: {
      decision: state.resolution.decision,
      rejectionReasons: state.resolution.rejectionReasons,
      paid: state.resolution.paid,
      agreementText: state.resolution.agreementText,
    },
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

const persistedStore = getPersistedStore();

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    language: persistedStore.language || i18n.locale,
    currentStep: persistedStore.currentStep || STEPS.select,
    isAdmin: null,
    stores: [],
    bundles: [],
    selectedBundle: persistedStore.selectedBundle || null,
    loginInfo: null,
    loanApplicationData: persistedStore.loanApplicationData || null,
    isLoading: false,
    hasError: false,
    notification: null,
    hasLoanApplicationError: false,
    loanApplicationDecision: null,
    loanReferenceNumber: persistedStore.loanReferenceNumber || null,
    consentInfo: getConsentObjectFromCookie(),
    individualizedTerms: [],
    signingData: null,
    signatureStatus: null,
    storeCode: persistedStore.storeCode || null,
    countryList: persistedStore.countryList || [],
    bundleOrders: {
      content: [],
    },
    geocoderError: null,
    resolution: {
      decision: persistedStore.resolution?.decision || null,
      rejectionReasons: persistedStore.resolution?.rejectionReasons || null,
      paid: persistedStore.resolution?.paid || null,
      agreementText: persistedStore.resolution?.agreementText || null,
    },
    step: persistedStore.step || 1,
  },
  actions: {
    [SET_LANGUAGE]({ commit }, language) {
      commit(SET_LANGUAGE, language);
    },
    [SET_COOKIE_CONSENT]({ commit }, consentLevels) {
      setCookie('cookie_consent', JSON.stringify(consentLevels), 365);
      commit(SET_COOKIE_CONSENT, {
        levels: consentLevels,
        revision: cookieRevision,
      });
    },
    [HANDLE_COOKIE_CONSENT]({ commit, state }) {
      if (state.consentInfo?.revision !== cookieRevision) {
        document.cookie =
          'cookie_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        commit(SET_COOKIE_CONSENT, null);
        return;
      }
      if (
        state.consentInfo?.levels?.includes(COOKIE_CONSENT_LEVELS.TARGETING)
      ) {
        window.fbq('consent', 'grant');
      } else {
        window.fbq('consent', 'revoke');
      }
      if (state.consentInfo?.levels?.includes(COOKIE_CONSENT_LEVELS.ANALYTIC)) {
        this._vm.$gtm?.enable(true);
        this._vm.$ga?.enable();
      } else {
        this._vm.$gtm?.enable(false);
        this._vm.$ga?.disable();
      }
    },
    async [GET_IS_ADMIN]({ commit, state }) {
      if (state.isAdmin !== null) {
        return state.isAdmin;
      }

      try {
        const isAdmin = await SubscriptionApi.isAdmin();
        commit(SET_IS_ADMIN, isAdmin);

        return isAdmin;
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [GET_STORES]({ commit }) {
      try {
        const stores = await SubscriptionApi.getStores();

        commit(SET_STORES, stores);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [GET_BUNDLES]({ commit }) {
      try {
        commit(IS_LOADING, true);

        const bundles = await SubscriptionApi.getBundles();

        const sortedBundles = bundles.map((bundle) => {
          if (bundle.services?.length) {
            bundle.services.sort((a, b) => a.displayIndex - b.displayIndex);
          }
          if (bundle.assets?.length) {
            bundle.assets.sort((a, b) => a.displayIndex - b.displayIndex);
          }
          return bundle;
        });

        commit(SET_BUNDLES, sortedBundles);
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    [SET_SELECTED_BUNDLE]({ commit }, bundle) {
      commit(SET_SELECTED_BUNDLE, bundle);
      commit(SET_CURRENT_STEP, bundle ? STEPS.apply : STEPS.select);
    },
    async [ASK_QUESTION]({ commit, state }, data) {
      try {
        commit(IS_LOADING, true);

        await SubscriptionApi.askQuestion({
          ...data,
          language: state.language,
        });
      } catch (error) {
        commit(HAS_ERROR, error);
        throw new Error(error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [GET_LOGIN_INFO]({ commit }) {
      try {
        commit(IS_LOADING, true);

        const response = await SubscriptionApi.getAuthInfo();
        commit(SET_LOGIN_INFO, response);

        router.push({ name: ROUTES.LOAN_APPLICATION.name });
      } catch (error) {
        commit(HAS_ERROR, error);
        throw new Error(error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    [SET_LOAN_APPLICATION_DATA]({ commit }, data) {
      commit(SET_LOAN_APPLICATION_DATA, data);
    },
    [SET_LOAN_FORM_DATA]({ commit }, data) {
      commit(SET_LOAN_FORM_DATA, data);
    },
    async [SUBMIT_LOAN_APPLICATION]({ commit, state }) {
      try {
        commit(IS_LOADING, true);

        const response = await SubscriptionApi.submitLoanApplication(
          state.loanApplicationData,
        );

        commit(SET_LOAN_REFERENCE_NUMBER, response.referenceNumber);

        const res = await SubscriptionApi.submitPayment(
          response.referenceNumber,
        );
        commit(SET_REJECTION_REASON, res.rejectionReasons);
        if (res.decision === DECISION_TYPES.APPROVED) {
          router.push({ name: ROUTES.LOAN_APPLICATION_SUCCESS.name });
        } else if (
          res.decision === DECISION_TYPES.DENIED ||
          res.decision === DECISION_TYPES.SUPPLEMENTING_REQUIRED
        ) {
          router.push({ name: ROUTES.DECISION_DENIED.name });
        }
        commit(SET_DECISION, res.decision);
        commit(SET_PAYMENT, res.paid);
        commit(SET_CURRENT_STEP, STEPS.paymentSubmitted);
        commit(SET_AGREEMENT_TEXT, res.agreementText);
      } catch (error) {
        commit(HAS_LOAN_APPLICATION_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [IS_BUNDLE_ORDER_COMPLETE]({ commit, state }) {
      try {
        commit(IS_LOADING, true);

        const referenceNumber = state.loanApplicationDecision.referenceNumber;

        const data = {
          language: state.language,
        };
        await SubscriptionApi.isBundleOrderComplete(referenceNumber, data);
      } catch (error) {
        router.push({ name: ROUTES.BANK_ID_FAIL.name });
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [GET_APPLICATION_STATUS]({ commit, state }) {
      try {
        const referenceNumber = state.loanApplicationDecision.referenceNumber;
        const applicationStatus = await SubscriptionApi.getApplicationStatus(
          referenceNumber,
        );

        commit(SET_APPLICATION_STATUS, applicationStatus);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [GET_COUNTRY_LIST]({ commit }) {
      try {
        const countryList = await SubscriptionApi.getCountryList();
        commit(SET_COUNTRY_LIST, countryList);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
  },
  mutations: {
    [RESET_STORE](state, isHalfSweep) {
      state.currentStep = STEPS.select;
      state.isAdmin = null;
      state.selectedBundle = null;
      state.loginInfo = null;
      state.loanApplicationData = null;
      state.loanReferenceNumber = null;
      state.loanApplicationDecision = isHalfSweep
        ? {
            referenceNumber: state.loanApplicationDecision.referenceNumber,
            accountNumber: state.loanApplicationDecision.accountNumber,
          }
        : null;
      state.storeCode = null;
    },
    [SET_LANGUAGE](state, language) {
      state.language = language;
    },
    [SET_COOKIE_CONSENT](state, consentInfo) {
      state.consentInfo = consentInfo;
    },
    [IS_LOADING](state, loading) {
      state.isLoading = loading;
    },
    [HAS_ERROR](state, error) {
      state.hasError = error;
    },
    [NOTIFICATION](state, notification) {
      state.notification = notification;
    },
    [SET_CURRENT_STEP](state, step) {
      state.currentStep = step;
    },
    [SET_IS_ADMIN](state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    [SET_STORES](state, stores) {
      state.stores = stores;
    },
    [SET_BUNDLES](state, bundles) {
      state.bundles = bundles;
    },
    [SET_SELECTED_BUNDLE](state, bundle) {
      state.selectedBundle = bundle;
    },
    [SET_LOGIN_INFO](state, data) {
      state.loginInfo = data;
    },
    [SET_LOAN_APPLICATION_DATA](state, data) {
      state.loanApplicationData = data;
    },
    [SET_LOAN_FORM_DATA](state, data) {
      state.loanApplicationData = {
        ...state.loanApplicationData,
        ...data,
      };
    },
    [HAS_LOAN_APPLICATION_ERROR](state, error) {
      state.hasLoanApplicationError = error;
    },
    [SET_LOAN_APPLICATION_DECISION](state, decision) {
      state.loanApplicationDecision = decision;
    },
    [SET_LOAN_REFERENCE_NUMBER](state, referenceNumber) {
      state.loanReferenceNumber = referenceNumber;
    },
    [SET_BUNDLE_SELECTED_STORE_CODE](state, storeCode) {
      state.storeCode = storeCode;
    },
    [SET_SIGNATURE_STATUS](state, status) {
      state.signatureStatus = status;
    },
    [SET_SIGNING_DATA](state, data) {
      state.signingData = data;
    },
    [SET_INDIVIDUALIZED_TERMS](state, termsData) {
      state.individualizedTerms.push(termsData);
    },
    [SET_APPLICATION_STATUS](state, status) {
      state.applicationStatus = status;
    },
    [SET_GEOCODER_ERROR](state, status) {
      state.geocoderError = status;
    },
    [SET_DECISION](state, decision) {
      state.resolution.decision = decision;
    },
    [SET_REJECTION_REASON](state, reason) {
      state.resolution.rejectionReasons = reason;
    },
    [SET_COUNTRY_LIST](state, countryList) {
      state.countryList = countryList;
    },
    [SET_APPLICATION_STEP](state, step) {
      state.step = step;
    },
    [SET_PAYMENT](state, paid) {
      state.resolution.paid = paid;
    },
    [SET_AGREEMENT_TEXT](state, agreementText) {
      state.resolution.agreementText = agreementText;
    },
  },
  getters: {
    hasConsentByLevel: (state) => (consentLevel) =>
      state.consentInfo?.levels?.includes(consentLevel),
  },
  //TODO: add correct price
  monthlyPrice: (state) => {
    state.bundle.monthlyPrice || 0;
  },
});
