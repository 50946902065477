var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'input',
    { 'has-error': _vm.error },
    { 'has-prefix': _vm.prefix },
    { 'has-suffix': _vm.suffix },
    { [`input--${_vm.size}`]: !!_vm.size },
    { 'is-honey-pot': _vm.isHoneyPot },
    { 'is-readonly': _vm.readonly },
  ]},[_c('label',{staticClass:"input__label",attrs:{"for":_vm.name}},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]}),(_vm.info)?_c('p',{staticClass:"input__info"},[_vm._v(_vm._s(_vm.info))]):_vm._e()],2),_c('div',{staticClass:"input__content"},[(_vm.prefix)?_c('span',{staticClass:"input__prefix",domProps:{"innerHTML":_vm._s(_vm.prefix)}}):_vm._e(),(_vm.suffix)?_c('span',{staticClass:"input__suffix",domProps:{"innerHTML":_vm._s(_vm.suffix)}}):_vm._e(),(_vm.type === 'textarea')?_c('textarea',{staticClass:"input__input",attrs:{"id":_vm.name,"aria-labelledby":_vm.name,"name":_vm.name,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"rows":_vm.rows,"maxlength":_vm.maxlength,"readonly":_vm.readonly,"aria-invalid":_vm.error,"aria-describedby":`error-${_vm.name}`},domProps:{"value":_vm.value},on:{"input":_vm.updateInput,"change":_vm.changeInput}}):_c('input',_vm._g({staticClass:"input__input",attrs:{"id":_vm.name,"aria-labelledby":_vm.name,"type":_vm.type,"name":_vm.name,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"tabindex":_vm.isHoneyPot ? -1 : 0,"readonly":_vm.readonly,"aria-invalid":_vm.error,"aria-describedby":`error-${_vm.name}`},domProps:{"value":_vm.value},on:{"input":_vm.updateInput,"change":_vm.changeInput,"focus":_vm.onFocus}},_vm.inputListeners)),(!isNaN(_vm.count) && _vm.maxlength)?_c('p',{staticClass:"input__count"},[_vm._v(" "+_vm._s(`${_vm.count}/${_vm.maxlength}`)+" ")]):_vm._e()]),(_vm.error)?_c('div',{staticClass:"input__error",attrs:{"id":`error-${_vm.name}`}},[_vm._t("error",function(){return [_vm._v(" "+_vm._s(_vm.error)+" ")]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }