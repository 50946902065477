import { API_BASE_URL } from '@/constants';
import HttpClient from '@/api/HttpClient';

export default {
  getStores() {
    return HttpClient.get({ url: `${API_BASE_URL}/public/store/search` });
  },
  getBundles() {
    return HttpClient.post({ url: `${API_BASE_URL}/public/bundle/search` });
  },
  askQuestion(data) {
    return HttpClient.post({
      url: `${API_BASE_URL}/public/app/customer-question`,
      data: data,
    });
  },
  submitLoanApplication(loanApplicationData) {
    return HttpClient.post({
      url: `${API_BASE_URL}/public/subscription`,
      data: loanApplicationData,
    });
  },
  submitPayment(referenceNumber) {
    return HttpClient.post({
      url: `${API_BASE_URL}/public/subscription/${referenceNumber}/targo/payment`,
    });
  },
  getAuthInfo() {
    return HttpClient.get({ url: '/public/auth-info' });
  },
  getCountryList() {
    return HttpClient.get({ url: '/public/country-code' });
  },
};
