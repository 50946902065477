export const getImageId = (bundleMediaFiles, mediaFileType) =>
  bundleMediaFiles.find((mediaFile) => mediaFile.type === mediaFileType)?.id;

export function isFlatRentDurationLessTwoYears(residenceDate) {
  const now = new Date();
  let amount = now - new Date(residenceDate);
  amount = Math.floor(amount / 1000);

  const days = Math.floor(amount / 86400);

  return days < 364 * 2;
}

export const buildLoanApplicationData = (formData, bundleId, storeCode) => {
  const isResidenceEmpty = Object.values(formData.residence).every(
    (val) => val === null,
  );

  if (isResidenceEmpty) {
    formData = Object.fromEntries(
      Object.entries(formData).filter(([key]) => key !== 'residence'),
    );
  }

  if (isFlatRentDurationLessTwoYears(formData.residentSince)) {
    formData.previousAddress = {
      ...formData.previousAddress,
      countryCode: 'DE',
    };
  } else {
    formData = Object.fromEntries(
      Object.entries(formData).filter(([key]) => key !== 'previousAddress'),
    );
  }
  return {
    bundleId,
    ...formData,
    iban: formData.iban?.toUpperCase(),
    personalIdCountryCode: 'DE',
    type: 'TARGO',
    storeCode: storeCode,
    currentAddress: {
      ...formData.currentAddress,
      countryCode: 'DE',
    },
    employmentJobSince: new Date(formData.employmentJobSince)
      .toISOString()
      .substr(0, 10),
    dateOfBirth: new Date(formData.dateOfBirth).toISOString().substr(0, 10),
    residentSince: new Date(formData.residentSince).toISOString().substr(0, 10),
  };
};

export const getDescriptionWithBullets = (description) => {
  return description?.split(';').map((value, index) => {
    return index === 0 ? value : `\u2022 ${value.trim()}`;
  });
};

export const formatPrice = (price) => {
  return price.toFixed(2).toString().replace('.', ',');
};
