<template>
  <b-modal
    :size="size"
    :hide-footer="hideFooter"
    :visible="value"
    header-class="borderless-header"
    footer-class="borderless-footer"
    @change="onChange"
  >
    <template #modal-header="{ close }">
      <slot name="header" @close="close" :close="close" />
    </template>
    <template #default>
      <slot></slot>
    </template>
    <template #modal-footer="{ cancel }">
      <slot name="footer" @cancel="cancel" :cancel="cancel" />
    </template>
  </b-modal>
</template>

<script>
import { constants } from '@/mixins';

export default {
  name: 'Modal',
  mixins: [constants],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'lg',
      validator: (value) => {
        return ['sm', 'md', 'lg', 'xl'].includes(value);
      },
    },
    hideFooter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalVisibility: false,
    };
  },

  watch: {
    value() {
      this.modalVisibility = this.$props.value;
    },
  },

  methods: {
    onChange(event) {
      this.$emit('change', event);
    },
  },
};
</script>

<style lang="scss">
.borderless-header {
  border-bottom: 0 !important;
}

.borderless-footer {
  border-top: 0 !important;
}
</style>
