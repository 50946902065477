<template>
  <div>
    <h6>{{ $t('FORM.YOUR_PERSONAL_DATA') }}</h6>
    <b-row class="margins__one">
      <b-col md="2">
        <Select
          v-model="form.gender"
          :label="`${$t('FORM.GENDER')} *`"
          name="gender"
          :options="GENDER_OPTIONS"
          :error="errors.first('gender')"
          v-validate="{ required: true }"
          :data-vv-as="$t('FORM.VALIDATOR.GENDER')"
          class="margins__mobile-md-double"
        >
          <template #default="{ option }">
            {{ $t(option.label) }}
          </template>
        </Select>
      </b-col>
      <b-col md="5">
        <Input
          v-model="form.firstName"
          :label="`${$t('FORM.FIRST_NAME')} *`"
          name="firstName"
          :placeholder="`${$t('FORM.PLACEHOLDER.FIRST_NAME')}`"
          autocomplete="first-name"
          :maxlength="50"
          :error="errors.first('firstName')"
          v-validate="{ required: true, regex: REGEX.DE.name }"
          :data-vv-as="$t('FORM.VALIDATOR.FIRST_NAME')"
          class="margins__mobile-md-double bold"
        />
      </b-col>
      <b-col md="5">
        <Input
          v-model="form.lastName"
          :label="`${$t('FORM.LAST_NAME')} *`"
          name="lastName"
          :placeholder="`${$t('FORM.PLACEHOLDER.LAST_NAME')}`"
          autocomplete="last-name"
          :maxlength="50"
          :error="errors.first('lastName')"
          v-validate="{ required: true, regex: REGEX.DE.name }"
          :data-vv-as="$t('FORM.VALIDATOR.LAST_NAME')"
          class="margins__mobile-md-double"
        />
      </b-col>
    </b-row>
    <b-row class="margins__one">
      <b-col md="3">
        <CustomDatePicker
          v-model="form.dateOfBirth"
          :label="`${$t('FORM.DATE_OF_BIRTH')} *`"
          name="dateOfBirth"
          :placeholder="`${$t('FORM.PLACEHOLDER.CALENDAR')}`"
          :error="errors.first('dateOfBirth')"
          v-validate="{
            required: true,
            isAdult: true,
          }"
          :max-date="maxDate"
          :data-vv-as="$t('FORM.VALIDATOR.DATE_OF_BIRTH')"
          :locale="$i18n.locale"
          :masks="masks"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="4">
        <Input
          v-model="form.birthPlace"
          :label="`${$t('FORM.BIRTH_PLACE')} *`"
          name="birthPlace"
          :maxlength="26"
          :error="errors.first('birthPlace')"
          v-validate="{ required: true, regex: REGEX.DE.birthPlace }"
          :data-vv-as="$t('FORM.VALIDATOR.BIRTH_PLACE')"
          class="margins__mobile-md-double"
        />
      </b-col>
    </b-row>

    <h6 class="mt-5">{{ $t('FORM.CURRENT_ADDRESS') }}</h6>
    <b-row class="margins__one">
      <b-col md="2">
        <Input
          v-model="form.currentAddress.postalCode"
          :label="`${$t('FORM.ZIP_CODE')} *`"
          name="currentpostalCode"
          :maxlength="5"
          placeholder="00000"
          autocomplete="postal-code"
          :error="errors.first('currentpostalCode')"
          v-validate="{
            required: true,
            regex: REGEX.DE.postalCode,
          }"
          :data-vv-as="$t('FORM.VALIDATOR.ZIP_CODE')"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="4">
        <Input
          v-model="form.currentAddress.city"
          :label="`${$t('FORM.CITY')} *`"
          name="currentCity"
          autocomplete="city"
          :error="errors.first('currentCity')"
          :maxlength="150"
          v-validate="{ required: true, regex: REGEX.DE.city }"
          :data-vv-as="$t('FORM.VALIDATOR.CITY')"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="4">
        <Input
          v-model="form.currentAddress.street"
          :label="`${$t('FORM.STREET')} *`"
          name="currentStreet"
          :maxlength="100"
          :error="errors.first('currentStreet')"
          v-validate="{ required: true, regex: REGEX.DE.streetAddress }"
          :data-vv-as="$t('FORM.VALIDATOR.STREET')"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="2">
        <Input
          v-model="form.currentAddress.houseNo"
          :label="`${$t('FORM.HOUSE_NUMBER')} *`"
          name="currentHouseNo"
          :error="errors.first('currentHouseNo')"
          :maxlength="10"
          v-validate="{ required: true, regex: REGEX.DE.houseNumber }"
          :data-vv-as="$t('FORM.VALIDATOR.HOUSE_NUMBER')"
          class="margins__mobile-md-double"
        />
      </b-col>
    </b-row>
    <b-row class="margins__one">
      <b-col md="3">
        <CustomDatePicker
          v-model="form.residentSince"
          :label="`${$t('FORM.SINCE_DATE')} *`"
          :placeholder="`${$t('FORM.PLACEHOLDER.CALENDAR')}`"
          name="residentSince"
          :error="errors.first('residentSince')"
          :data-vv-as="$t('FORM.VALIDATOR.SINCE_DATE')"
          v-validate="{ required: true }"
          :max-date="maxDate"
          :locale="$i18n.locale"
          :masks="masks"
          class="margins__mobile-md-double"
        />
      </b-col>
    </b-row>

    <div v-if="isPrevAddressVisible" class="mt-4">
      <p class="text-grey text-small">
        {{ $t('FORM.PREVIOUS_ADDRESS') }}
      </p>
      <b-row class="margins__one">
        <b-col md="2">
          <Input
            v-model="form.previousAddress.postalCode"
            :label="previousLabel('FORM.ZIP_CODE')"
            name="prevPostalCode"
            :maxlength="5"
            placeholder="00000"
            autocomplete="previous-postal-code"
            :error="errors.first('prevPostalCode')"
            v-validate="{
              required: isPrevAddressVisible,
              regex: REGEX.DE.postalCode,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.ZIP_CODE')"
            class="margins__mobile-md-double"
          />
        </b-col>
        <b-col md="4">
          <Input
            v-model="form.previousAddress.city"
            :label="previousLabel('FORM.CITY')"
            name="prevCity"
            autocomplete="previous-city"
            :error="errors.first('prevCity')"
            v-validate="{
              required: isPrevAddressVisible,
              regex: REGEX.DE.city,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.CITY')"
            :maxlength="150"
            class="margins__mobile-md-double"
          />
        </b-col>
        <b-col md="4">
          <Input
            v-model="form.previousAddress.street"
            :label="previousLabel('FORM.STREET')"
            name="prevStreet"
            autocomplete="previous-street"
            :error="errors.first('prevStreet')"
            v-validate="{
              required: isPrevAddressVisible,
              regex: REGEX.DE.streetAddress,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.STREET')"
            :maxlength="100"
            class="margins__mobile-md-double"
          />
        </b-col>
        <b-col md="2">
          <Input
            v-model="form.previousAddress.houseNo"
            :label="previousLabel('FORM.HOUSE_NUMBER')"
            name="prevHouseNo"
            :error="errors.first('prevHouseNo')"
            v-validate="{
              required: isPrevAddressVisible,
              regex: REGEX.DE.houseNumber,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.HOUSE_NUMBER')"
            maxlength="10"
            class="margins__mobile-md-double"
          />
        </b-col>
      </b-row>
    </div>
    <slot :validate="validate" :form="form" />
  </div>
</template>
<script>
import { Input, Select, CustomDatePicker } from '@/components';
import { constants, createOptions } from '@/mixins';
import { GENDER_OPTIONS } from '@/constants';

import { isFlatRentDurationLessTwoYears } from '@/utils/BundleUtil';

export default {
  name: 'ApplicantPersonalData',
  mixins: [constants, createOptions],
  components: {
    Input,
    Select,
    CustomDatePicker,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      isPrevAddressVisible: false,
      GENDER_OPTIONS: GENDER_OPTIONS,
    };
  },
  created() {
    this.form = {
      ...this.formData,
      ...this.form,
    };
  },
  mounted() {
    this.scrollToTop();
  },
  watch: {
    'form.residentSince': {
      handler(val) {
        if (isFlatRentDurationLessTwoYears(val)) {
          this.form.previousAddress = { ...this.form.previousAddress };
          this.isPrevAddressVisible = true;
        } else {
          this.$delete(this.form, 'previousAddress');
          this.isPrevAddressVisible = false;
        }
      },
      deep: true,
    },
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    previousLabel(label) {
      return this.isPrevAddressVisible ? `${this.$t(label)} *` : this.$t(label);
    },

    async validate() {
      const valid = await this.$validator.validateAll();
      return valid;
    },
  },

  computed: {
    maxDate() {
      return new Date();
    },
    masks() {
      return { input: 'DD/MM/YYYY' };
    },
  },
};
</script>
