<template>
  <footer class="footer">
    <Container>
      <Separator size="medium" hasLine />
      <Container>
        <b-row>
          <b-col>
            <b-col class="footer-heading">
              <h6>{{ $t('FOOTER.HEADING.COMPANY') }}</h6>
            </b-col>
            <b-col
              v-for="(item, index) in getFooterItemsByType('CompanyItem')"
              :key="`${index}-about`"
            >
              <a
                :href="item.url"
                class="footer-item text-color-black"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{{ $t(`FOOTER.ITEM.${item.label}`) }}</span>
              </a>
            </b-col>
          </b-col>
          <b-col>
            <b-col class="footer-heading">
              <h6>{{ $t('FOOTER.HEADING.SOCIAL_MEDIA') }}</h6>
            </b-col>
            <b-col
              v-for="(item, index) in getFooterItemsByType('SocialMediaItems')"
              :key="`${index}-socials`"
            >
              <a
                :href="item.url"
                class="footer-item text-color-black"
                target="_blank"
                rel="noopener noreferrer"
              >
                <component :is="item.icon" />
                <span class="social-media-item">{{
                  $t(`FOOTER.ITEM.${item.label}`)
                }}</span>
              </a>
            </b-col>
          </b-col>
          <b-col>
            <b-col class="footer-heading">
              <h6>{{ $t('FOOTER.HEADING.CONTACT') }}</h6>
            </b-col>
            <b-col
              v-for="(item, index) in getFooterItemsByType('ContactItems')"
              :key="`${index}-contacts`"
            >
              <span class="text-uppercase footer-item">
                {{ $t(`FOOTER.ITEM.${item.label}`) }}
              </span>
              <template v-if="item.label === 'EMAIL'">
                <a
                  :href="`mailto:${item.value}`"
                  class="text-color-black"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ item.value }}
                </a>
              </template>
              <template v-else>
                {{ getStreetName(item.value) }}
                <br />
                {{ getPostalCode(item.value) }}
              </template>
            </b-col>
          </b-col>
          <b-col class="text-align-end">
            <a
              href="https://corporate.stihl.de/de/ueber-stihl "
              class="text-color-black"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h6>{{ $t('FOOTER.HEADING.MORE_INFO') }}</h6>
            </a>
          </b-col>
        </b-row>
      </Container>
      <Separator size="medium" hasLine />
      <Container>
        <b-row>
          <b-col>
            <a
              :href="dataProtection"
              class="footer_link text-color-black"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('FOOTER.HEADING.PRIVACY_CONSENT') }}
            </a>
          </b-col>
          <b-col>
            <a
              :href="generalTerms"
              class="footer_link text-color-black"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('FOOTER.HEADING.GENERAL_TERMS') }}
            </a>
          </b-col>
          <b-col>
            <a
              href="https://www.stihl.de/de/impressum"
              class="footer_link text-color-black"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('FOOTER.HEADING.IMPRINT') }}
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-align-end">
            <BLink @click="showCookieInfo" class="footer_link text-color-black">
              {{ $t('FOOTER.HEADING.COOKIE_POLICY') }}
            </BLink>
          </b-col>
          <b-col class="text-align-end">
            <BLink
              @click="showCookieConsentSettings"
              class="footer_link text-color-black"
            >
              {{ $t('FOOTER.HEADING.COOKIE_SETTINGS') }}
            </BLink>
          </b-col>

          <b-col>
            <a
              id="footer_link"
              href="https://www.stihl.de/de/service-events/haeufige-fragen/online-shop-faq/entsorgung-batterien-altgeraete"
              target="_blank"
              class="footer_link text-color-black"
              rel="noopener noreferrer"
            >
              {{ $t('FOOTER.HEADING.DISPOSAL_INFO') }}
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <a
              href="https://www.stihl.de/de/service-events/haeufige-fragen/online-shop-faq/entsorgung-von-verpackungen"
              class="footer_link text-color-black"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('FOOTER.HEADING.DISPOSAL_PACK_INFO') }}
            </a>
          </b-col>

          <b-col>
            <a
              href="https://www.stihl.de/de/garantiebedingungen"
              target="_blank"
              rel="noopener noreferrer"
              class="footer_link text-color-black"
            >
              <span>
                {{ $t('FOOTER.HEADING.WARRANTY') }}
              </span>
            </a>
          </b-col>

          <b-col></b-col>
        </b-row>
      </Container>
    </Container>
    <Separator size="medium" />
  </footer>
</template>

<script>
import { Container, Separator, Button } from '@/components';
import {
  BIconFacebook,
  BIconInstagram,
  BIconYoutube,
  BIconLinkedin,
  BLink,
} from 'bootstrap-vue';
import { resetCookieConsent } from '@/utils/CookieUtil';
import { footerDataMap } from '@/constants';

export default {
  name: 'Footer',
  components: {
    Container,
    Separator,
    BIconFacebook,
    BIconInstagram,
    BIconYoutube,
    BIconLinkedin,
    BLink,
    Button,
  },
  methods: {
    showCookieConsentSettings() {
      resetCookieConsent();
      this.$emit('open-cookie-consent-modal');
    },
    showCookieInfo() {
      this.$emit('open-cookie-info-modal');
    },
    getFooterItemsByType(footerType) {
      return footerDataMap.get(footerType);
    },
    getStreetName(address) {
      return address.split(',')[0];
    },
    getPostalCode(address) {
      return address.split(',')[1];
    },
  },
  computed: {
    generalTerms() {
      return `${window.location.origin}/documents/GeneralTerms.pdf`;
    },
    dataProtection() {
      return `${window.location.origin}/documents/Datenschutzerklärung.pdf`;
    },
  },
};
</script>

<style lang="scss">
.footer {
  background-color: $background-color;
}

.text-align-end {
  text-align: end;
}

.footer-heading {
  padding-bottom: 20px;
}

.text-color-black {
  color: $color-black;
}

.footer-item {
  font-size: 0.95em;
}

.social-media-item {
  padding-left: 8px;
}

.footer_link {
  display: flex;
  align-items: center;
  height: 100%;
  color: $color-black;
  font-family: $font-family-header;
  font-weight: $header-link-font-weight;
  font-size: $font-size-extra-small;
  letter-spacing: $header-link-letter-spacing;
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  min-height: 2.5rem;

  @include min-width(sm) {
    font-size: $header-link-font-size;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &:hover {
    text-decoration: none;
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
