<template>
  <div>
    <div class="pb-5 px-md-5">
      <StepsCounter :steps="steps" :activeStep="currentStep" />
    </div>
    <form
      @submit="handleSubmit"
      class="loan-application-form"
      autocomplete="off"
    >
      <Input
        v-model="formData.honeypot"
        name="bot-field"
        label="Don't fill this out if you're human"
        autocomplete="random"
        isHoneyPot
      />

      <Margins>
        <transition name="fade-router" mode="out-in">
          <component :is="applicationStep" :formData.sync="formData">
            <template #default="{ validate, form }">
              <b-row align-v="center" align-h="center" class="margins__double">
                <b-col cols="auto" v-if="currentStep !== 1">
                  <Button variant="link" @click="onClickBack">
                    {{ $t('BACK') }}
                  </Button>
                </b-col>
                <template v-if="lastStep">
                  <b-col cols="auto">
                    <Button
                      variant="inverse-primary"
                      @click="handleSubmit({ validate, form })"
                    >
                      {{ $t('CONTINUE') }}
                    </Button>
                  </b-col>
                </template>
                <template v-else>
                  <b-col cols="auto">
                    <Button
                      variant="inverse-primary"
                      @click="onClickForward({ validate, form })"
                    >
                      {{ $t('FORWARD') }}
                    </Button>
                  </b-col>
                </template>
              </b-row>
            </template>
          </component>
        </transition>
      </Margins>
    </form>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  SET_LOAN_APPLICATION_DATA,
  GET_COUNTRY_LIST,
  SET_APPLICATION_STEP,
  SET_LOAN_FORM_DATA,
} from '@/types';
import { Button, Margins, Input } from '@/components';

import { constants, createOptions } from '@/mixins';
import { getPersistedStore } from '@/utils';
import { buildLoanApplicationData } from '@/utils/BundleUtil';
import { LOAN_APPLICATION_STEPS } from '@/constants';
import ApplicantPersonalData from './Steps/ApplicantPersonalData.vue';
import ApplicantPassportData from './Steps/ApplicantPassportData.vue';
import ApplicantProfessionData from './Steps/ApplicantProfessionData.vue';
import ApplicantBudgetData from './Steps/ApplicantBudgetData.vue';
import ApplicantIBANData from './Steps/ApplicantIBANData.vue';
import ApplicantPersonalPhoneData from './Steps/ApplicantPersonalPhoneData.vue';
import StepsCounter from './StepsCounter.vue';

export default {
  name: 'LoanApplicationForm',
  mixins: [constants, createOptions],
  components: {
    Button,
    Margins,
    Input,
    ApplicantPersonalData,
    ApplicantPassportData,
    ApplicantProfessionData,
    ApplicantBudgetData,
    ApplicantIBANData,
    ApplicantPersonalPhoneData,
    StepsCounter,
  },
  data() {
    return {
      formData: {
        currentAddress: {},
        previousAddress: {},
      },
      currentStep: 1,
      initialStep: 1,
      LOAN_APPLICATION_STEPS,
    };
  },

  watch: {
    loanApplicationData: {
      handler(val) {
        this.formData = { ...this.formData, ...val };
      },
      deep: true,
    },
  },

  created() {
    const localLoanApplicationData = getPersistedStore().loanApplicationData;

    if (!localLoanApplicationData) {
      return false;
    }

    this.formData = {
      ...localLoanApplicationData,
    };

    this.currentStep = this.step;
  },
  mounted() {
    this.GET_COUNTRY_LIST();
  },

  methods: {
    ...mapActions([
      SET_LOAN_APPLICATION_DATA,
      GET_COUNTRY_LIST,
      SET_LOAN_FORM_DATA,
    ]),
    ...mapMutations([SET_APPLICATION_STEP]),

    setFormData(formData) {
      this.SET_LOAN_FORM_DATA(formData);
    },

    async handleSubmit({ validate, form }) {
      if (this.formData.honeypot) {
        return false;
      }

      this.setFormData(form);

      const valid = await validate();
      if (!valid) {
        return false;
      }

      let data = this.loanApplicationData;

      if (data.residence && data.residence.permitUntil) {
        data.residence.permitUntil = new Date(data.residence.permitUntil)
          .toISOString()
          .substr(0, 10);
      }
      if (data.employmentJobSince) {
        data.employmentJobSince = new Date(data.employmentJobSince)
          .toISOString()
          .substr(0, 10);
      }
      const clonedFormData = JSON.parse(JSON.stringify(data));

      clonedFormData.privacyConsent = !!clonedFormData.privacyConsent;

      this.SET_LOAN_APPLICATION_DATA(
        buildLoanApplicationData(
          clonedFormData,
          this.selectedBundle.id,
          this.storeCode,
        ),
      );

      this.$router.push({ name: this.ROUTES.LOAN_APPLICATION_LOADING.name });

      this.formData = {
        currentAddress: {},
        previousAddress: {},
      };
      this.$validator.reset();
      this.SET_APPLICATION_STEP(this.initialStep);
    },
    previousLabel(label) {
      return this.isPrevAddressVisible ? `${this.$t(label)} *` : this.$t(label);
    },
    async onClickForward({ validate, form }) {
      const isValid = await validate();
      if (isValid) {
        this.setFormData(form);
        const nextStep = this.currentStep++;
        this.SET_APPLICATION_STEP(nextStep);
      }
      return;
    },
    onClickBack() {
      const prevStep = this.currentStep--;
      this.SET_APPLICATION_STEP(prevStep);
    },
  },

  computed: {
    ...mapState(['selectedBundle', 'loanApplicationData', 'storeCode', 'step']),

    lastStep() {
      return this.currentStep === 6;
    },

    applicationStep() {
      return LOAN_APPLICATION_STEPS[this.currentStep];
    },
    steps() {
      return Object.keys(LOAN_APPLICATION_STEPS).length;
    },
  },
};
</script>
<style lang="scss">
.hint {
  color: $color-primary;
  text-transform: capitalize;
}

.fade-router-enter-active,
.fade-router-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-router-enter,
.fade-router-leave-active {
  opacity: 0;
}
</style>
