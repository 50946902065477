<template>
  <div class="container">
    <div class="row">
      <div>
        <h2>{{ $t('COOKIE_INFO.MODAL.HEADER') }}</h2>
      </div>
    </div>
    <div class="row padding-top-10">
      <div v-for="info in infoBlock" :key="info.title" class="padding-top-10">
        <h6>{{ info.title }}</h6>
        <p>{{ info.text }}</p>
      </div>
    </div>

    <div
      v-for="(type, index) in tableTypes"
      :key="`${index}-${type}`"
      class="row padding-top-10 padding-bottom-10"
    >
      <div
        v-for="description of consentsDescriptions[type]"
        :key="description.title"
      >
        <p class="text-strong">{{ $t(description.title) }}</p>
        <p>{{ $t(description.text) }}</p>
      </div>
      <Table>
        <template #default>
          <thead>
            <tr>
              <th
                scope="col"
                v-for="header in cookieInfoTableHeaders"
                :key="header"
                class="px-1 text-left"
              >
                {{ $t(`COOKIE_INFO.MODAL.TABLE.HEADERS.${header}`) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(content, key) of tablesContent[type]">
              <tr :key="key">
                <td v-for="(col, index) in content" :key="index" class="px-1">
                  {{ col }}
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </Table>
    </div>
  </div>
</template>
<script>
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { Table } from '@/components';
import {
  cookieInfoTableHeaders,
  tableTypes,
  tablesContent,
  consentsDescriptions,
} from './cookieConstants';

export default {
  name: 'CookieInfo',
  mixins: [constants],
  components: { Table },
  data() {
    return {
      cookieInfoTableHeaders,
      tableTypes,
      tablesContent,
      consentsDescriptions,
    };
  },
  computed: {
    infoBlock() {
      return getDynamicTranslation(this.$i18n, 'COOKIE_INFO.MODAL.INFO');
    },
  },
};
</script>
