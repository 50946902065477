<template>
  <div>
    <div class="cookie-consent-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-8 padding-bottom-15">
            <h6 class="cookie-banner-text">
              {{ $t('COOKIE_CONSENT.BANNER.INFO') }}
            </h6>
            <div class="padding-top-16 cookie-consent-banner_links">
              <a
                v-for="policyLink in POLICY_LINKS"
                :key="policyLink.name"
                :href="policyLink.href"
                target="_blank"
                rel="noopener noreferrer"
                class="cookie-banner-text"
              >
                {{ $t(policyLink.text) }}
              </a>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="col padding-bottom-15">
              <Button
                variant="inverse-primary"
                block
                size="large"
                @click="acceptAllCookies"
              >
                {{ $t('COOKIE_CONSENT.BANNER.ACCEPT') }}
              </Button>
            </div>
            <div class="col padding-bottom-15">
              <Button
                variant="inverse-primary"
                block
                size="large"
                @click="rejectAllCookies"
              >
                {{ $t('COOKIE_CONSENT.BANNER.REJECT') }}
              </Button>
            </div>
            <div class="col padding-bottom-15">
              <Button
                variant="default"
                block
                size="large"
                @click="showCookieConsentModal"
              >
                {{ $t('COOKIE_CONSENT.BANNER.SETTINGS') }}
              </Button>
            </div>
            <div v-if="multipleLanguages" class="col">
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-overlay" />
  </div>
</template>

<script>
import { Button } from '@/components';
import { mapActions, mapState } from 'vuex';
import { HANDLE_COOKIE_CONSENT, SET_COOKIE_CONSENT } from '@/types';
import { COOKIE_CONSENT_LEVELS, POLICY_LINKS } from '@/constants';
import { constants } from '@/mixins';
import LanguageSwitcher from '@/containers/LanguageSwitcher';

export default {
  name: 'CookieBanner',
  mixins: [constants],
  components: {
    LanguageSwitcher,
    Button,
  },
  data() {
    return {
      POLICY_LINKS,
    };
  },
  methods: {
    ...mapActions([SET_COOKIE_CONSENT, HANDLE_COOKIE_CONSENT]),
    showCookieConsentModal() {
      this.$emit('open-cookie-consent-modal');
    },
    acceptAllCookies() {
      this.SET_COOKIE_CONSENT(Object.keys(COOKIE_CONSENT_LEVELS));
      this.HANDLE_COOKIE_CONSENT(this.$gtm, this.$ga, {});
    },
    rejectAllCookies() {
      this.SET_COOKIE_CONSENT([COOKIE_CONSENT_LEVELS.NECESSARY]);
      this.HANDLE_COOKIE_CONSENT(this.$gtm, this.$ga, {});
    },
  },
  computed: {
    ...mapState(['consentInfo']),

    multipleLanguages() {
      return this.STORE.availableLocales.length > 1;
    },
  },
};
</script>

<style lang="scss">
.cookie-consent-banner {
  padding-top: 20px;
  padding-bottom: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $color-primary-light;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  text-align: left;
  z-index: 1000;
  justify-content: center;
  &_links {
    display: flex;
    justify-content: space-between;
  }
}

.cookie-banner {
  &-text {
    font-weight: $font-weight;
    text-transform: initial;
    font-size: 18px;
  }
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.page-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  overflow-y: hidden;
}
</style>
