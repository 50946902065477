<template>
  <div>
    <DatePicker
      :value="value"
      :first-day-of-week="2"
      :update-on-input="false"
      :timezone="timezone"
      :mode="mode"
      :min-date="minDate"
      :max-date="maxDate"
      :available-dates="availableDates"
      :disabled-dates="disabledDates"
      :is-range="isRange"
      :locale="locale"
      :masks="masks"
      @input="onInput"
    >
      <template #default="{ inputValue, inputEvents }">
        <InputField
          :name="name"
          :value="inputValue"
          :error="error"
          :readonly="readonly"
          :placeholder="placeholder"
          autocomplete="off"
          :input-listeners="inputEvents"
        >
          <template #label>
            <slot name="label">
              {{ label }}
            </slot>
          </template>
          <template #error>{{ error }}</template>
        </InputField>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar';
import { Input as InputField } from '@/components';

export default {
  name: 'CustomDatePicker',

  components: {
    InputField,
    DatePicker,
  },
  props: {
    value: {
      type: [String, Date, null],
      default: null,
    },
    minDate: {
      type: [String, Date, null],
      default: null,
    },
    maxDate: {
      type: [String, Date, null],
      default: null,
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    labelHidden: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    error: { type: String, default: '' },
    label: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    timezone: { type: String, default: '' },
    mode: {
      type: String,
      default: 'date',
    },
    availableDates: {
      type: Object,
      default: null,
    },
    disabledDates: {
      type: Object,
      default: null,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    masks: {
      type: Object,
      default: () => ({}),
    },
    locale: {
      type: String,
      default: '',
    },
  },

  methods: {
    onInput(evt) {
      this.$emit('input', evt);
    },
  },
  computed: {
    propsToPass() {
      const result = {};
      if (this.locale) {
        result.locale = this.locale;
      }

      if (this.masks) {
        result.masks = this.masks;
      }

      return result;
    },
  },
};
</script>
