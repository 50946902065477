<template>
  <div>
    <h6>{{ $t('FORM.YOUR_INCOME_AND_EXPENCES') }}</h6>
    <b-row class="margins__one">
      <b-col md="4">
        <InputField
          v-model.trim="form.netIncome"
          :label="`${$t('FORM.NET_INCOME')} *`"
          name="netIncome"
          suffix="€"
          :error="errors.first('netIncome')"
          v-validate="{ required: true, numeric: true }"
          :data-vv-as="$t('FORM.VALIDATOR.NET_INCOME')"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="4">
        <Select
          v-model.trim="form.homeOwnerRenter"
          :label="`${$t('FORM.HOUSE_TYPE')} *`"
          name="homeOwnerRenter"
          :options="HOUSING_TYPE"
          :error="errors.first('homeOwnerRenter')"
          v-validate="{ required: true }"
          :data-vv-as="$t('FORM.VALIDATOR.HOUSE_TYPE')"
          class="margins__mobile-md-double"
        >
          <template #default="{ option }">{{ $t(option.label) }}</template>
        </Select>
      </b-col>
      <b-col md="4">
        <InputField
          v-model.trim="form.mortgageRentAmount"
          name="mortgageRentAmount"
          suffix="€"
          :error="errors.first('mortgageRentAmount')"
          v-validate="{ required: true, numeric: true }"
          :data-vv-as="$t('FORM.VALIDATOR.COST_OF_PROPERTY')"
          class="margins__mobile-md-double"
        >
          <template #label>
            <Tooltip target="permitUntil" placement="topleft">
              <template #parent>
                <span id="permitUntil" class="inline">
                  {{ $t('FORM.COST_OF_PROPERTY') }} *
                </span>
              </template>
              <template #default>
                <span> {{ $t('FORM.COST_OF_PROPERTY_TOOLTIP') }} </span>
              </template>
            </Tooltip>
          </template>
        </InputField>
      </b-col>
    </b-row>
    <b-row class="margins__one">
      <b-col md="4">
        <InputField
          v-model="form.noChildren"
          :label="`${$t('FORM.NUMBER_OF_CHILDREN')} *`"
          name="noChildren"
          :error="errors.first('noChildren')"
          v-validate="{ required: true, min: 0, numeric: true }"
          :data-vv-as="$t('FORM.VALIDATOR.NUMBER_OF_CHILDREN')"
          class="margins__mobile-md-double"
        />
      </b-col>

      <b-col md="4">
        <InputField
          v-model="form.noCars"
          :label="`${$t('FORM.NUMBER_OF_CARS')} *`"
          name="noCars"
          :error="errors.first('noCars')"
          v-validate="{ required: true, min: 0, numeric: true }"
          :data-vv-as="$t('FORM.VALIDATOR.NUMBER_OF_CARS')"
          class="margins__mobile-md-double"
        />
      </b-col>
      <b-col md="4">
        <InputField
          v-model="form.noMotoBikes"
          :label="`${$t('FORM.NUMBER_OF_MOTORBIKES')} *`"
          name="noMotoBikes"
          :error="errors.first('noMotoBikes')"
          v-validate="{ required: true, min: 0, numeric: true }"
          :data-vv-as="$t('FORM.VALIDATOR.NUMBER_OF_MOTORBIKES')"
          class="margins__mobile-md-double"
        />
      </b-col>
    </b-row>
    <slot :validate="validate" :form="form" />
  </div>
</template>
<script>
import { Input as InputField, Select, Tooltip } from '@/components';
import { constants, createOptions } from '@/mixins';
import { HOUSING_TYPE } from '@/constants';

export default {
  name: 'ApplicantBudgetData',
  mixins: [constants, createOptions],
  components: {
    InputField,
    Select,
    Tooltip,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {},
      HOUSING_TYPE,
    };
  },
  created() {
    this.form = { ...this.formData, ...this.form };
  },
  mounted() {
    this.scrollToTop();
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    async validate() {
      const valid = await this.$validator.validateAll();
      return valid;
    },
  },
};
</script>
