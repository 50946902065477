<template>
  <div class="marketing-faq">
    <Card padding="small" color="grey">
      <CardHeader :header="$t('LANDING.FREQUENT_QUESTIONS')" />
      <Margins>
        <Accordion :options="questions" class="margins__triple">
          <template #default="{ description, option }">
            <template v-if="option === 'MODEL_SELECTION'">
              <i18n :path="description" tag="p" class="margins__double">
                <template #concludeContract>
                  <p>{{ $t('LANDING.MODEL_SELECTION.PARAGRAPH_1') }}</p>
                </template>
                <template #dealerguidance>
                  <p>{{ $t('LANDING.MODEL_SELECTION.PARAGRAPH_2') }}</p>
                </template>
              </i18n>
            </template>
            <template v-if="option === 'KEEP_OR_UPDATE'">
              <p>
                {{ $t('LANDING.CONTRACT_INFO.HEADING') }}
              </p>
            </template>
          </template>
          <template #title="{ title }">
            {{ $t(title) }}
          </template>
        </Accordion>
        <div class="margins__triple text-left">
          <Button
            @click="$router.push({ name: ROUTES.FAQ.name })"
            variant="inverse-primary"
          >
            {{ $t('LANDING.GO_TO_FAQ') }}
          </Button>
        </div>
      </Margins>
    </Card>
  </div>
</template>
‌
<script>
import { Accordion, Button, Card, Margins, CardHeader } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'MarketingFaq',
  mixins: [constants],
  components: {
    Accordion,
    Button,
    Card,
    Margins,
    CardHeader,
  },
  computed: {
    questions() {
      return [
        {
          name: 'MODEL_SELECTION',
          title: 'LANDING.MODEL_SELECTION.HEADING',
          description: 'LANDING.MODEL_SELECTION.PARAGRAPH',
        },
        {
          name: 'KEEP_OR_UPDATE',
          title: 'LANDING.KEEP_OR_UPDATE',
          description: 'LANDING.CONTRACT_INFO.INFO_BLOCK',
        },
      ];
    },
    keepOrUpdate() {
      return [
        {
          name: 'list-item-1',
          text: 'LANDING.CONTRACT_INFO.PARAGRAPH_1',
        },
        {
          name: 'list-item-2',
          text: 'LANDING.CONTRACT_INFO.PARAGRAPH_2',
        },
        {
          name: 'list-item-3',
          text: 'LANDING.CONTRACT_INFO.PARAGRAPH_3',
        },
        {
          name: 'list-item-4',
          text: 'LANDING.CONTRACT_INFO.PARAGRAPH_4',
        },
      ];
    },
  },
};
</script>
